import { PropertySafetyFilled } from "@ant-design/icons";
import React, { Fragment } from "react";
import Header from "./Header";

export const Layout = (props) => {
  return (
    <Fragment>
        <div className="form__fields m-2 iframe__block">
            <div className="top__navigation">
                <Header/>
            </div>
            <div>
                {props.children}
            </div>
            {/* <div className="slidebar__section">
                <button className="primary-btn slidebar__btn m-0">
                  <i className="fa fa-cog" aria-hidden="true"></i>
                </button>
                <div className="right__sidebar">
                    <div className="font__block">
                      <p className="font__bold">Choose Font</p>
                      <div className="font__options">
                          <p><span>Font option1</span> <input type="radio" /></p>
                          <p><span>Font option2</span> <input type="radio" /></p>
                          <p><span>Font option3</span> <input type="radio" /></p>
                          <p><span>Font option4</span> <input type="radio" /></p>
                          <p><span>Font option5</span> <input type="radio" /></p>
                      </div>
                    </div>
                    <hr/>
                    <div className="colorselection__block">
                      <p className="font__bold">Hover Effect</p>
                      <div className="color__section">
                          <div className="left__block w-50">
                              <input type="color" value="#000022" />
                              <p>#000022</p>
                          </div>
                          <div className="right__block w-50">
                              <input type="color" value="#BBBBF9" />
                              <div className="input__block mb-0">
                                <input type="text" value="#BBBBF9" />
                              </div>
                          </div>
                      </div>
                    </div>
                    <hr/>
                    <div className="colorselection__block">
                      <p className="font__bold">Column Header Accent</p>
                      <div className="color__section">
                          <div className="left__block w-50">
                              <input type="color" value="#000000" />
                              <p>#000000</p>
                          </div>
                          <div className="right__block w-50">
                              <input type="color" value="#24ABE2" />
                              <div className="input__block mb-0">
                                <input type="text" value="#24ABE2" />
                              </div>
                          </div>
                      </div>
                    </div>
                    <hr/>
                    <div className="colorselection__block">
                      <p className="font__bold">Link Color</p>
                      <div className="color__section">
                          <div className="left__block w-50">
                              <input type="color" value="#828282" />
                              <p>#828282</p>
                          </div>
                          <div className="right__block w-50">
                              <input type="color" value="#DD0000" />
                              <div className="input__block mb-0">
                                <input type="text" value="#DD0000" />
                              </div>
                          </div>
                      </div>
                    </div>
                    <hr/>
                    <div className="colorselection__block">
                      <p className="font__bold">Call to Action Button</p>
                      <div className="color__section">
                          <div className="left__block w-50">
                              <input type="color" value="#000022" />
                              <p>#000022</p>
                          </div>
                          <div className="right__block w-50">
                              <input type="color" value="#3B4452" />
                              <div className="input__block mb-0">
                                <input type="text" value="#3B4452" />
                              </div>
                          </div>
                      </div>
                      <div className="sidebar__btn mt-4">
                          <button className="primary-btn mr-2">Save</button>
                          <button className="primary-btn">Reset</button>
                      </div>
                    </div>
                    <hr/>
                </div>
            </div> */}
        </div>
    </Fragment>
   
  )
 
};

export default Layout;