import React, { forwardRef } from "react";
import WatchBuilderImg from "../../assets/images/WatchBuilderImage.jpg";

const PrintOutComp = (props, ref) => {
    return (
        <React.Fragment>
          <div ref={ref} className="row d-flex">
            <div className="col-lg-12 col-md-12">
            <div className="col-lg-6 col-md-6">
              <img src={WatchBuilderImg} alt="watch" />
            </div>
            <div className="col-lg-6 col-md-6">
              <table>
                <tbody>
                    <tr>
                      <th className="bold">Size & Bracelet Type (STEP 1)</th>
                    </tr>
                      <tr>
                        <td>Watch Type :</td>
                        <td>{props.data.watchSize}</td>
                      </tr>
                      <tr>
                        <td>Bracelet Style:</td>
                        <td>{props.data.braceletStyle}</td>
                      </tr>
                      <tr>
                        <td>Bracelet Metal:</td>
                        <td>{props.data.braceletMetal}</td>
                      </tr>
                    <tr>
                      <th className="bold">Bezel Type (STEP 2)</th>
                    </tr>
                    <tr>
                        <td>Bezel Type :</td>
                        <td>{props.data.bezelType}</td>
                    </tr>
                    <tr>
                        <td>Bezel Gemstones:</td>
                        <td>{props.data.bezelGemstoneType}</td>
                    </tr>
                    <tr>
                        <td>Bezel Gemstone Shape:</td>
                        <td>{props.data.bezelGemstoneShape}</td>
                    </tr>
                    <tr>
                        <td>Total Carat Weight:</td>
                        <td>{props.data.carat}</td>
                    </tr>
                    <tr>
                        <td>Bezel Diamond Quality:</td>
                        <td>{props.data.bezelDiamondQuality}</td>
                    </tr>
                    <tr>
                      <th className="bold">Dial type (STEP 3)</th>
                    </tr>
                    <tr>
                        <td>Marker Type :</td>
                        <td>{props.data.markerType}</td>
                    </tr>
                    <tr>
                        <td>Dial Color:</td>
                        <td>{props.data.dialColor}</td>
                    </tr>
                    <tr>
                        <td>Dial Type:</td>
                        <td>{props.data.dialType}</td>
                    </tr>
                </tbody>
              </table>
            </div>
            </div>
          </div>
        </React.Fragment>
      );
}

export default forwardRef(PrintOutComp);