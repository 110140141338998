import { httpApps } from "../helper/http-common";

class WatchCustomizerService {
  GetWatchBindData(inputData) {
    return httpApps.post("/WatchCistomizer/BindWatchBuilderData", inputData);
  }
  GetWatchBuilderByGfinventoryID(inputData) {
    return httpApps.post("/WatchCistomizer/GFIwithWatchBuilderData", inputData);
  }
  GetWatchRequestInfo(inputData) {
    return httpApps.post("/WatchCistomizer/WatchSendMailForInquiry", inputData)
  }
  GetDropHintRequest(inputData) {
    return httpApps.post("/WatchCistomizer/WatchSendMailForHint", inputData)
  }
  GetEmailAFriend(inputData) {
    return httpApps.post("/WatchCistomizer/SendMailToFriend", inputData)
  }
  GetDataForScheduling(inputData) {
    return httpApps.post("/WatchCistomizer/GetSchedulingDropDownlist", inputData)
  }
  GetScheduleAppointer(inputData) {
    return httpApps.post("/WatchCistomizer/SaveAppointment", inputData)
  }
  GetDealerAddress(inputData) {
    return httpApps.post("/WatchCistomizer/GetDealerLocationData", inputData)
  }
  
}

export default new WatchCustomizerService();

// ${Object.keys(temp1).length !== 0 && temp1['dtBraceletType'][index].drpHTML === "disabled" ? "disabledSec" : ""} `}>

//${item.drpHTML === "disabled" ? "disabledSec" : ""} `}>