import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

import "antd/dist/antd.css";
import "../src/assets/css/style.css"

// REDUX
import { Provider } from "react-redux";
// import store from "./redux/store";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-notifications/lib/notifications.css";

ReactDOM.render(
  <App />,

  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
