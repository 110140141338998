import React, { useState, useRef, useEffect } from "react";
import { Select, Button, Modal } from "antd";
import { Tabs, Tab } from "react-bootstrap";
import Watch1 from "../../assets/images/watch01.png";
import Watch2 from "../../assets/images/watch02.png";
import Watch3 from "../../assets/images/watch03.png";
import traslate from "../../i18n/translate";
import PrintOutComp from "./PrintOutComp";
import { DatePicker, Space } from "antd";
import WatchCustomizerService from "../../services/watchCustomiserService";
import { useReactToPrint } from "react-to-print";
import { useLocation } from "react-router-dom";
import { NotificationManager } from "react-notifications";
import moment from "moment";

import { Accordion } from "react-bootstrap";
import { logDOM } from "@testing-library/react";

const initialState = {
  dealerLink: "",
  additionalInfo: "WatchBuilder",
  watchSize: "26mm",
  braceletStyle: "",
  braceletMetal: "",
  bezelType: "",
  bezelMetal: "",
  bezelGemstoneType: "",
  bezelGemstoneShape: "",
  dialType: "",
  markerType: "",
  markerColor: "",
  dialColor: "",
  bezelDiamondQuality: "",
  diamondMarkerType: "",
  lastSelect: "",
  carat: "",
};

const WatchCustomizer = () => {
  const [switchValue, setswitchValue] = useState(true);
  const { Option } = Select;
  const initialRequestInfo = {
    yourName: "",
    yourEmail: "",
    yourPhone: "",
    message: "",
    dealerID: "",
    productID: "",
    detailURL: "",
    imagePath: "",
    carat: "",
    yourCompName: "",
    yourStorePhone: "",
    productDescription: "",
  };
  const initialEmailFriend = {
    yourName: "",
    yourMail: "",
    friendMail: "",
    message: "",
    dealerID: "",
    productID: "",
    detailURL: "",
    imagePath: "",
    carat: "",
    subject: "",
    productDescription: "",
  };

  const initialDropHint = {
    dealerID: "",
    recptEmail: "",
    recptName: "",
    recptReason: "",
    recpMessage: "",
    hintRecptGiftDeadline: "",
    productID: "",
    detailURL:
      "https://designers.gemfind.net/WatchBuilder.aspx?DealerLink=720&ThemeName=DefaultTheme#&ID=41&Ct=0",
    themeName: "DefaultTheme",
    imagePath: "",
    watchSize: "",
  };
  // const appntState = {
  //   dealerID: "",
  //   customerId:"0",
  //   appType:"",
  //   message:"",
  //   appDate:"",
  //   appTime:"",
  //   carat:"",
  //   productDescription:"Original fluted bezel",
  //   email:"",
  // }
  const appntState = {
    appType: "",
    message: "",
    appDate: "",
    appTime: "",
    email: "",
    retailerID: "",
    dealerID: "",
    schedulPnone: "",
    did: "",
    shape: "",
    ctw: "",
    strDiamondLinkURL: "",
    step: "",
    themeName: "",
    loadFromMasterlink: true,
  };
  const [initialLoad, setInitialLoad] = useState(true);
  const [size31Disable, setSize31Disable] = useState(true);
  const [size26Disable, setSize26Disable] = useState(true);
  const [size36Disable, setSize36Disable] = useState(true);
  const [watchSelect, setWatchSelect] = useState(initialState);
  const [watchSelectGFInventory, setWatchSelectGFInventory] =
    useState(initialState);
  const [watchReqData, setWatchReqData] = useState();
  const [dataGFInventory, setDataGFInventory] = useState();
  const [requestInfo, setRequestInfo] = useState(initialRequestInfo);
  const [dropHint, setDropHint] = useState(initialDropHint);
  const [emailFriend, setEmailFriend] = useState(initialEmailFriend);
  const [temp1, setTemp1] = useState([]);
  const [temp2, setTemp2] = useState([]);
  const [DidGFInventoryID, setDidGFInventoryID] = useState("");
  const [DiamondLinkURL, setDiamondLinkURL] = useState("");
  const [selectType, setSelectType] = useState({
    type: "",
    sameValue: false,
  });
  const [watchImg, setWatchImg] = useState("");
  const [appntmntDD, setAppntmntDD] = useState();
  const [schedule, setSchedule] = useState(appntState);
  const [initialGFFlag, setInitialGFFlag] = useState(false);
  const [showGemWt, setShowGemWt] = useState(false);
  const [watchDescription, setWatchDescription] = useState("");
  let componentRef = useRef();
  const search = useLocation().search;
  //const dealerId = new URLSearchParams(search).get("DealerLink");
  //const dealerIdByParam = "720";
  // Modal Functions
  const [isModalVisible1, setIsModalVisible1] = useState(false);
  const [isModalVisible2, setIsModalVisible2] = useState(false);
  const [isModalVisible3, setIsModalVisible3] = useState(false);
  const [isModalVisible4, setIsModalVisible4] = useState(false);
  const [isModalVisible5, setIsModalVisible5] = useState(false);

  const [braceletStyleDisable, setBraceletStyleDisable] = useState({
    Jubilee: false,
    Oyster: false,
    President: false,
  });
  const [braceletMetalDisable, setBraceletMetalDisable] = useState({
    "18KW": false,
    "18KY": false,
    "18KY/SS": false,
    "Stainless Steel": false,
  });
  const [bezelTypeDisable, setBezelTypeDisable] = useState({
    Beadset: false,
    Channel: false,
    Fluted: false,
    Pave: false,
    Smooth: false,
  });
  const [bezelMetalDisable, setBezelMetalDisable] = useState({
    "18KW": false,
    "18KY": false,
    "Stainless Steel": false,
  });
  const [bezelGemstonesDisable, setBezelGemstonesDisable] = useState({
    Diamond: false,
    "Diamond & Emerald": false,
    "Diamond & Ruby": false,
    "Diamond & Sapphire": false,
  });
  const [bezelGemstoneShapeDisable, setBezelGemstoneShapeDisable] = useState({
    Baguette: false,
    Princess: false,
    Round: false,
    Trapezoid: false,
  });
  const [bezelDiamondQualityDisable, setBezelDiamondQualityDisable] = useState({
    standard: false,
    superior: false,
    Value: false,
  });
  const [dialTypeDisable, setDialTypeDisable] = useState({
    "Original DateJust 6917": false,
    "Day-Date": false,
  });
  const [markerTypeDisable, setMarkerTypeDisable] = useState({
    "Custom MOP Dial": false,
    "Custom MOP Diamond": false,
    "Custom MOP Roman Numeral": false,
    "Custom Stone Dial": false,
    "Custom Stone Plain": false,
    "Custom Stone Roman Numeral": false,
    Diamond: false,
    Emerald: false,
    Plain: false,
    "Roman Numeral": false,
    Ruby: false,
    Sapphire: false,
    Stick: false,
    "Custom Annular Diamond": false,
    "Custom Baguette Diamond": false,
    "Custom Diamond": false,
    "Custom Elliptical Diamond": false,
    "Custom Pave Diamond": false,
  });
  const [dialColorDisable, setDialColorDisable] = useState({
    Black: false,
    "Black MOP": false,
    Blue: false,
    Champagne: false,
    Chocolate: false,
    "Golden MOP": false,
    Lapis: false,
    Lavender: false,
    Majenta: false,
    Meteorite: false,
    Onyx: false,
    Pink: false,
    "Pink MOP": false,
    Red: false,
    Rose: false,
    Silver: false,
    White: false,
    "White MOP": false,
    Green: false,
    "Mink MOP": false,
    Pietersite: false,
  });

  //Validation RequestInfo starts
  const [reqValidations, setReqValidations] = useState({
    yourName: "",
    yourEmail: "",
    yourCompName: "",
    yourPhone: "",
    yourStorePhone: "",
    message: "",
  });
  //Validation DropHint starts
  const [hintValidations, setHintValidations] = useState({
    recptName: "",
    recptEmail: "",
    recptReason: "",
    hintRecptGiftDeadline: "",
  });
  //Validation EmailFren
  const [emailFrenValidations, setEmailFrenValidations] = useState({
    yourName: "",
    yourMail: "",
    friendMail: "",
    subject: "",
    message: "",
  });
  //Validation Schedule
  const [scheduleValidations, setScheduleValidations] = useState({
    email: "",
    appType: "",
    appDate: "",
    appTime: "",
    message: "",
  });
  useEffect(() => {
    handleInitialData();
  }, []);
  useEffect(() => {
    handleWatchBindData();
  }, [watchSelect, selectType]);

  function openMapHandler() {
    var MapAddr = "";
    MapAddr = appntmntDD?.DealerAddress !== "" ? appntmntDD.DealerAddress : "";
    MapAddr += appntmntDD.DealerCity;
    MapAddr += appntmntDD.DealerState;
    MapAddr += appntmntDD.DealerZip;

    var map_setting =
      "toolbar=yes,location=no,directories=yes,menubar=yes,resizable=yes,";
    window.open(
      "https://maps.google.com/maps?daddr=" + MapAddr,
      "",
      map_setting
    );
  }
  const handleInitialData = () => {
    debugger;
    var caratVal = "";
    var dealerLink = "";
    var watchSize = "";
    if (watchSelect.watchSize === "") {
      watchSize = "26mm";
    } else {
      watchSize = watchSelect.watchSize;
    }
    dealerLink = new URLSearchParams(search).get("DealerLink");
    if (dealerLink === undefined || dealerLink === "") {
      dealerLink = "720";
    }
    setWatchSelect((prevState) => ({
      ...prevState,
      dealerLink: dealerLink,
      // carat : caratVal
    }));
    caratVal = window.location.href.split("Ct=")[1];
    var DiamondLinkURL = window.location.href;
    setDiamondLinkURL(DiamondLinkURL);
    // console.log("PAGE URL IS :" + DiamondLinkURL);
    var GfInventoryId;
    if (caratVal === undefined) {
      GfInventoryId = window.location.href.split("ID=")[1];
    } else {
      // GfInventoryId = (window.location.href.split('ID=')[1]).split('&Ct=')[0];
      GfInventoryId = window.location.href.split("ID=")[0].split("&Ct=")[0];
    }
    setDidGFInventoryID(GfInventoryId);
    if (caratVal !== "0" && caratVal !== undefined) {
      //replaceDescription(caratVal);
      //$('#divForCarat .carats_drop_down').val(caratVal);
      //EnableDisableDiamondQualityOptionByCarat(caratVal);

      if (watchSelect.bezelGemstoneType !== "Diamond") {
        setShowGemWt(true);
      } else {
        setShowGemWt(false);
      }
    }

    if (watchSize || watchSelect.watchSize === "36mm") {
      // $('#liBaguette2').show();
      // $('#liTrapezoid').hide();
    } else {
      // $('#liBaguette2').hide();
      // $('#liTrapezoid').show();
    }

    if (GfInventoryId === undefined) {
      //handleWatchBindData();
      setInitialGFFlag(false);
      setWatchSelect((prevState) => ({
        ...prevState,
        watchSize: watchSize,
        dealerLink: dealerLink,
        // carat : caratVal
      }));
      //handleWatchBindData()
    } else {
      setInitialGFFlag(true);
      handleWatchbuilderByGfinventoryID(GfInventoryId, dealerLink);
    }
  };

  const handleWatchBindData = (arrName, initialFlag) => {
    try {
      //console.log(arrName,initialFlag);
      if (watchSelect.dealerLink !== "") {
        WatchCustomizerService.GetWatchBindData(watchSelect).then(
          (response) => {
            let message = response.data.message;
            let responseData = response.data.responseData;
            if (message === "Success") {
              console.log(responseData);
              if (responseData && Object.keys(responseData).length !== 0) {
                setWatchDescription("");
                setWatchReqData(responseData);
                if (!initialLoad) {
                  setSize26Disable(true);
                  setSize31Disable(true);
                  setSize36Disable(true);
                  for (let i of responseData.dtWatchSize) {
                    if (i.WatchSize === "26mm ") {
                      setSize26Disable(false);
                    } else if (i.WatchSize === "36mm") {
                      setSize36Disable(false);
                    } else if (i.WatchSize === "31mm") {
                      setSize31Disable(false);
                    }
                  }
                }

                setTemp1(responseData);

                //   if(Object.keys(temp1).length === 0 && Object.keys(temp2).length === 0) {
                //     setTemp1(responseData);
                //   }else if(Object.keys(temp1).length !== 0 && temp2.length === 0) {
                //     if(selectType.sameValue) {
                //       //setTemp1(responseData);
                //     }else {
                //       let keyName = Object.keys(temp1);

                //       for(let i=0; i< keyName.length; i++) {
                //         if(keyName[i] !== selectType.type) {
                //           temp1[keyName[i]].map((item,index) => {
                //               let agg = ""
                //               if(keyName[i] === "dtBraceletType") {
                //                 agg = "Bracelet Style"
                //               }else if(keyName[i] === "dtBraceletMetal") {
                //                 agg = "Bracelet Metal"
                //               }else if(keyName[i] === "dtBezelType") {
                //                 agg = "BezelType"
                //               }else if(keyName[i] === "dtBezelMetal") {
                //                 agg = "Bezel Metal"
                //               }else if(keyName[i] === "dtBezelGemstones") {
                //                 agg = "BezelGemstones"
                //               }else if(keyName[i] === "dtBezelGemstoneShape") {
                //                 agg = "Bezel Gemstone Shape"
                //               }else if(keyName[i] === "dtBezelDiamondQuality") {
                //                 agg = "Bezel Diamond Quality"
                //               }else if(keyName[i] === "dtDialType") {
                //                 agg = "DialType"
                //               }else if(keyName[i] === "dtMarkerType") {
                //                 agg = "Marker Type"
                //               }else if(keyName[i] === "dtDialColor") {
                //                 agg = "DialColor"
                //               }
                //               if(agg !== ""){
                //                 responseData[keyName[i]].map((newItem,index1) => {
                //                     if(item[agg] === newItem[agg]) {
                //                       if(responseData[keyName[i]][index1].drpHTML !== undefined && Object.keys(responseData[keyName[i]][index1].drpHTML).length !== 0) {
                //                         if(responseData[keyName[i]][index1].drpHTML === "disabled") {
                //                             let newTemp = temp1[keyName[i]]
                //                             newTemp[index].drpHTML = "disabled"
                //                             setTemp1((prevState) => ({
                //                               ...prevState,
                //                               [keyName[i]]: newTemp,
                //                             }));
                //                           }
                //                         }
                //                     }
                //                 })
                //               }
                //           });
                //         }
                //       }
                //       setTemp2(responseData)
                //     }
                //   }else if(temp1.length !== 0 && temp2.length !== 0) {
                //     if(selectType.sameValue) {
                //       setTemp1(temp2);
                //     }else {
                //       setTemp1(temp2);
                //       let keyName = Object.keys(temp1);
                //       for(let i=0; i< keyName.length; i++) {
                //         if(keyName[i] !== selectType.type){
                //           temp1[keyName[i]].map((item,index) => {
                //             let agg = ""
                //             if(keyName[i] === "dtBraceletType") {
                //               agg = "Bracelet Style"
                //             }else if(keyName[i] === "dtBraceletMetal") {
                //               agg = "Bracelet Metal"
                //             }else if(keyName[i] === "dtBezelType") {
                //               agg = "BezelType"
                //             }else if(keyName[i] === "dtBezelMetal") {
                //               agg = "Bezel Metal"
                //             }else if(keyName[i] === "dtBezelGemstones") {
                //               agg = "BezelGemstones"
                //             }else if(keyName[i] === "dtBezelGemstoneShape") {
                //               agg = "Bezel Gemstone Shape"
                //             }else if(keyName[i] === "dtBezelDiamondQuality") {
                //               agg = "Bezel Diamond Quality"
                //             }else if(keyName[i] === "dtDialType") {
                //               agg = "DialType"
                //             }else if(keyName[i] === "dtMarkerType") {
                //               agg = "Marker Type"
                //             }else if(keyName[i] === "dtDialColor") {
                //               agg = "DialColor"
                //             }
                //             if(agg !== ""){
                //               responseData[keyName[i]].map((newItem,index1) => {
                //                 console.log(newItem[agg])
                //                 if(item[agg] === newItem[agg]) {
                //                   if(responseData[keyName[i]][index1].drpHTML !== undefined && Object.keys(responseData[keyName[i]][index1].drpHTML).length !== 0) {
                //                     if(responseData[keyName[i]][index1].drpHTML === "disabled") {
                //                       console.log(keyName[i],index1,"Disabled");
                //                       let newTemp = temp1[keyName[i]]
                //                       newTemp[index].drpHTML = "disabled"
                //                       setTemp1((prevState) => ({
                //                         ...prevState,
                //                         [keyName[i]]: newTemp,
                //                       }));
                //                     }
                //                   }
                //                 }
                //               })
                //             }
                //         });
                //       }
                //     }
                //     setTemp2(responseData);
                //     }
                // }
                let SetDefaultDialImage1 = false;
                let SetDefaultDialImage2 = false;
                let normalURL = "https://designers.Gemfind.net/";
                let ImagePath = "";
                var desc = "";
                if (
                  responseData.dtProductDescription[0]["ProductDescription"] !==
                  undefined
                )
                  setWatchDescription(
                    responseData.dtProductDescription[0]["ProductDescription"]
                  );
                if (
                  watchSelect.dialType === "" &&
                  watchSelect.dialColor === "" &&
                  watchSelect.markerType === "" &&
                  watchSelect.markerColor === "" &&
                  watchSelect.diamondMarkerType === ""
                ) {
                  if (
                    responseData.dtDialType !== null &&
                    responseData.dtDialColor != null
                  ) {
                    responseData.dtDialType.map((item, index) => {
                      if (item["DialType"] === "Original DateJust 6917") {
                        SetDefaultDialImage1 = true;
                      }
                    });
                  }
                }

                if (
                  watchSelect.dialType === "" &&
                  watchSelect.dialColor === "" &&
                  watchSelect.markerType === "" &&
                  watchSelect.markerColor === "" &&
                  watchSelect.diamondMarkerType === ""
                ) {
                  if (
                    responseData.dtDialType !== null &&
                    responseData.dtDialColor != null
                  ) {
                    for (var i = 0; i < responseData.dtDialType.length; i++) {
                      if (
                        responseData.dtDialType[i]["DialType"] ===
                        "Original DateJust 6917"
                      ) {
                        SetDefaultDialImage1 = true;
                        break;
                      }
                    }
                    for (var i = 0; i < responseData.dtDialColor.length; i++) {
                      if (
                        responseData.dtDialColor[i]["DialColor"] ===
                        "Champagne" ||
                        responseData.dtDialColor[i]["DialColor"] === "White"
                      ) {
                        SetDefaultDialImage2 = true;
                        break;
                      }
                    }
                  }
                }

                if (
                  watchSelect.dialType === "Original DateJust 6917" &&
                  watchSelect.markerType === ""
                ) {
                  if (watchSelect.dialColor === "Champagne") {
                    ImagePath =
                      normalURL +
                      "WatchBuilderImage.aspx?Bracelet=" +
                      responseData.dtWatch[0]["BraceletImage"] +
                      "&Bezel=" +
                      responseData.dtWatch[0]["BezelImage"] +
                      "&Dial=Original Stick Dial Champagne  ys.png&wi=281&hi=418";
                  } else if (watchSelect.dialColor === "Silver") {
                    ImagePath =
                      normalURL +
                      "WatchBuilderImage.aspx?Bracelet=" +
                      responseData.dtWatch[0]["BraceletImage"] +
                      "&Bezel=" +
                      responseData.dtWatch[0]["BezelImage"] +
                      "&Dial=Original Stick Dial Silver  ys.png&wi=281&hi=418";
                  } else if (watchSelect.dialColor === "White") {
                    ImagePath =
                      normalURL +
                      "WatchBuilderImage.aspx?Bracelet=" +
                      responseData.dtWatch[0]["BraceletImage"] +
                      "&Bezel=" +
                      responseData.dtWatch[0]["BezelImage"] +
                      "&Dial=Original Stick Dial White  ys.png&wi=281&hi=418";
                  } else if (watchSelect.dialColor === "Black") {
                    ImagePath =
                      normalURL +
                      "WatchBuilderImage.aspx?Bracelet=" +
                      responseData.dtWatch[0]["BraceletImage"] +
                      "&Bezel=" +
                      responseData.dtWatch[0]["BezelImage"] +
                      "&Dial=Original Stick Dial Black  ys.png&wi=281&hi=418";
                  } else if (watchSelect.dialColor === "Blue") {
                    ImagePath =
                      normalURL +
                      "WatchBuilderImage.aspx?Bracelet=" +
                      responseData.dtWatch[0]["BraceletImage"] +
                      "&Bezel=" +
                      responseData.dtWatch[0]["BezelImage"] +
                      "&Dial=Original Stick Dial Blue  ys.png&wi=281&hi=418";
                  } else if (watchSelect.dialColor === "Rose") {
                    ImagePath =
                      normalURL +
                      "WatchBuilderImage.aspx?Bracelet=" +
                      responseData.dtWatch[0]["BraceletImage"] +
                      "&Bezel=" +
                      responseData.dtWatch[0]["BezelImage"] +
                      "&Dial=Original Stick Dial Rose  ws.png&wi=281&hi=418";
                  }
                }

                if (
                  watchSelect.dialColor === "Pink" &&
                  watchSelect.markerColor === ""
                ) {
                  if (
                    (watchSelect.braceletStyle === "Jubilee" &&
                      watchSelect.braceletMetal === "18KY/SS") ||
                    (watchSelect.braceletStyle === "Oyster" &&
                      watchSelect.braceletMetal === "18KY/SS") ||
                    (watchSelect.braceletStyle === "President" &&
                      watchSelect.braceletMetal === "18KY")
                  ) {
                    ImagePath =
                      normalURL +
                      "WatchBuilderImage.aspx?Bracelet=" +
                      responseData.dtWatch[0]["BraceletImage"] +
                      "&Bezel=" +
                      responseData.dtWatch[0]["BezelImage"] +
                      "&Dial=6917 RNDS Pink ys.png&wi=281&hi=418";
                  } else if (
                    (watchSelect.braceletStyle === "Jubilee" &&
                      watchSelect.braceletMetal === "Stainless Steel") ||
                    (watchSelect.braceletStyle === "Oyster" &&
                      watchSelect.braceletMetal === "Stainless Steel") ||
                    (watchSelect.braceletStyle === "President" &&
                      watchSelect.braceletMetal === "18KW")
                  ) {
                    ImagePath =
                      normalURL +
                      "WatchBuilderImage.aspx?Bracelet=" +
                      responseData.dtWatch[0]["BraceletImage"] +
                      "&Bezel=" +
                      responseData.dtWatch[0]["BezelImage"] +
                      "&Dial=6917 RNDS Pink ws.png&wi=281&hi=418";
                  }
                }

                var HasWhiteBezel = false;
                for (var i = 0; i < responseData.dtBezelMetal.length; i++) {
                  if (responseData.dtBezelMetal[i]["Bezel Metal"] === "18KW") {
                    HasWhiteBezel = true;
                    break;
                  }
                }

                var tmpbezelimage = "";
                var tmpdialimage = "";
                var tmpbraceletimage = "";
                var tmpbezelMetal = "";
                var BezelImage = "";
                var BraceletImage = "";
                var DialImage = "";
                var caratVal;
                if (
                  watchSelect.braceletStyle === "" &&
                  watchSelect.braceletMetal === ""
                ) {
                  tmpbraceletimage = "DATEJUST Y Fluted 18KSS Jubilee.jpg";
                } else {
                  tmpbraceletimage = responseData.dtWatch[0]["BraceletImage"];
                }
                ///----------------------------- code for bezel image in 26mm size for carat values ---------------------------//
                if (
                  (watchSelect.bezelType === "" &&
                    watchSelect.braceletStyle === "") ||
                  watchSelect.bezelType === ""
                ) {
                  if (
                    watchSelect.braceletStyle === "Stainless Steel" ||
                    watchSelect.braceletMetal === "18KW"
                  ) {
                    tmpbezelimage = "Original 18KW Fluted Bezel.png";
                    tmpbezelMetal = "18KW";
                  } else if (
                    watchSelect.braceletStyle === "President" &&
                    watchSelect.braceletMetal === ""
                  ) {
                    if (
                      responseData.dtWatch[0]["BraceletImage"] ===
                      "DATEJUST W Fluted President.jpg"
                    ) {
                      tmpbezelimage = "Original 18KW Fluted Bezel.png";
                      tmpbezelMetal = "18KW";
                    } else {
                      tmpbezelimage = "Original 18KY Fluted Bezel.png";
                      tmpbezelMetal = "18KY";
                    }
                  } else {
                    tmpbezelimage = "original 18ky fluted bezel.png";
                    tmpbezelMetal = "18KY";
                  }
                } else if (
                  watchSelect.bezelType !== "" &&
                  watchSelect.bezelMetal === "" &&
                  HasWhiteBezel &&
                  watchSelect.bezelGemstoneShape === "" &&
                  watchSelect.bezelGemstoneShape === ""
                ) {
                  if (watchSelect.bezelType === "Fluted") {
                    tmpbezelimage = "Original 18KW Fluted Bezel.png";
                    tmpbezelMetal = "18KW";
                  } else if (watchSelect.bezelType === "Smooth") {
                    tmpbezelimage = "Original 18KW Smooth Bezel.png";
                    tmpbezelMetal = "18KW";
                  } else if (watchSelect.bezelType === "Channel") {
                    tmpbezelimage = "LCW 1.png";
                    tmpbezelMetal = "18KW";
                  } else if (watchSelect.bezelType === "Beadset") {
                    tmpbezelimage = "LBW 40 .75 SUP.png";
                    tmpbezelMetal = "18KW";
                  } else if (watchSelect.bezelType === "Pave") {
                    tmpbezelimage = "LBW DR 1.png";
                    tmpbezelMetal = "18KW";
                  }
                } else {
                  if (
                    watchSelect.bezelType === "Channel" &&
                    watchSelect.bezelGemstoneShape === "Round" &&
                    watchSelect.bezelMetal === "18KW"
                  ) {
                    tmpbezelimage =
                      caratVal === "1.50"
                        ? "LCW 1.50 SUP.png"
                        : "LCW 1.00 SUP.png";
                    tmpbezelMetal = "18KW";
                  } else if (
                    watchSelect.bezelType === "Channel" &&
                    watchSelect.bezelGemstoneShape === "Round" &&
                    watchSelect.bezelMetal === "18KY"
                  ) {
                    tmpbezelimage =
                      caratVal === "1.50"
                        ? "LCY 1.50 SUP.png"
                        : "LCY 1.00 SUP.png";
                    tmpbezelMetal = "18KY";
                  } else {
                    tmpbezelimage = responseData.dtWatch[0]["BezelImage"];
                    tmpbezelMetal = responseData.dtWatch[0]["Bezel Metal"];
                  }

                  if (
                    watchSelect.bezelType === "Beadset" &&
                    watchSelect.bezelGemstoneShape === "Round"
                  ) {
                    if (watchSelect.bezelMetal === "18KY") {
                      tmpbezelMetal = watchSelect.bezelMetal;
                    } else {
                      tmpbezelMetal = "18KW";
                    }

                    if (caratVal === "0.75" || caratVal === ".75") {
                      tmpbezelimage =
                        watchSelect.bezelMetal === "18KY"
                          ? "LBY 40 .75 SUP.png"
                          : "LBW 40 .75 SUP.png";
                    } else if (caratVal === "1.50")
                      tmpbezelimage =
                        watchSelect.bezelMetal === "18KY"
                          ? "LBY 32 1.50 SUP.png"
                          : "LBW 32 1.50 SUP.png";
                    else if (caratVal == "1.00")
                      tmpbezelimage =
                        watchSelect.bezelMetal === "18KY"
                          ? "LBY 38 1.00 SUP.png"
                          : "LBW 38 1.00 SUP.png";
                  }
                }
                //// ------------------------------- End -----------------------------------------///

                if (
                  watchSelect.bezelGemstoneType === "Diamond" &&
                  watchSelect.bezelType === "" &&
                  watchSelect.bezelGemstoneShape === ""
                ) {
                  tmpbezelimage = "LCW 1.png";
                  tmpbezelMetal = "18KW";
                }
                if (SetDefaultDialImage1 && SetDefaultDialImage2) {
                  tmpdialimage = "Original Stick Dial White  ys.png";
                } else tmpdialimage = responseData.dtWatch[0]["DialImage"];

                ///----------------------------- code for bezel image in 31mm size for carat values ---------------------------//

                if (watchSelect.watchSize === "31mm") {
                  if (watchSelect.bezelType === "Beadset") {
                    tmpbezelimage = "Mid-Size .90 SUP W.png";
                    tmpbezelMetal = "18KW";
                  }
                  if (
                    watchSelect.bezelMetal === "18KW" &&
                    watchSelect.bezelType === "Beadset"
                  ) {
                    tmpbezelimage =
                      caratVal === "1.50"
                        ? "Mid-Size 1.50 SUP W.png"
                        : "Mid-Size .90 SUP W.png";
                    tmpbezelMetal = "18KW";
                  } else if (
                    watchSelect.bezelMetal === "18KY" &&
                    watchSelect.bezelType === "Beadset"
                  ) {
                    tmpbezelimage =
                      caratVal === "1.50"
                        ? "Mid-Size 1.50 SUP Y.png"
                        : "Mid-Size .90 SUP Y.png";
                    tmpbezelMetal = "18KY";
                  }
                }
                ////----------------------------- End ----------------------------------------///
                /// ========================== Code for bezel image in 36mm Size for carat values =============================///
                if (watchSelect.watchSize === "36mm") {
                  if (watchSelect.bezelMetal === "Channel") {
                    tmpbezelimage = "MCW 1.50 SUP.png";
                    tmpbezelMetal = "18KW";
                  }
                  if (watchSelect.bezelType === "") {
                    if (
                      watchSelect.braceletMetal === "Stainless Steel" ||
                      watchSelect.braceletMetal === "18KW"
                    ) {
                      tmpbezelimage = "Original 18KW Fluted Bezel.png";
                      tmpbezelMetal = "18KW";
                    } else {
                      tmpbezelimage = "Original 18KY Fluted Bezel.png";
                      tmpbezelMetal = "18KY";
                    }
                    if (
                      watchSelect.braceletStyle === "President" &&
                      watchSelect.braceletMetal === ""
                    ) {
                      tmpbezelimage = "Original 18KW Fluted Bezel.png";
                      tmpbezelMetal = "18KW";
                    }
                  }
                  if (
                    watchSelect.bezelType === "Channel" &&
                    watchSelect.bezelGemstoneShape === "Round" &&
                    watchSelect.bezelMetal === "18KW"
                  ) {
                    tmpbezelimage =
                      caratVal === "1.50"
                        ? "MCW 1.50 SUP.png"
                        : "MCW 2.50 SUP.png";
                    tmpbezelMetal = "18KW";
                  } else if (
                    watchSelect.bezelType === "Channel" &&
                    watchSelect.bezelGemstoneShape === "Round" &&
                    watchSelect.bezelMetal === "18KY"
                  ) {
                    tmpbezelimage =
                      caratVal === "1.50"
                        ? "MCY 1.50 SUP.png"
                        : "MCY 2.50 SUP.png";
                    tmpbezelMetal = "18KY";
                  } else if (
                    watchSelect.bezelType === "Channel" &&
                    watchSelect.bezelGemstoneShape !== "Round"
                  ) {
                    tmpbezelimage = responseData.dtWatch[0]["BezelImage"];
                    tmpbezelMetal = responseData.dtWatch[0]["Bezel Metal"];
                  }
                  if (
                    watchSelect.bezelType === "Channel" &&
                    watchSelect.bezelGemstoneShape === ""
                  ) {
                    tmpbezelimage =
                      watchSelect.bezelMetal === "18KY"
                        ? "MCY 1.50 SUP.png"
                        : "MCW 1.50 SUP.png";
                    if (watchSelect.bezelMetal === "18KY")
                      tmpbezelMetal = watchSelect.bezelMetal;
                    else tmpbezelMetal = "18KW";
                  }

                  if (watchSelect.bezelType === "Beadset") {
                    tmpbezelimage = "MBW 52 1.10 SUP.png";
                    tmpbezelMetal = "18KW";
                  }
                  if (
                    watchSelect.bezelType === "Beadset" &&
                    watchSelect.bezelGemstoneShape === "Round"
                  ) {
                    //caratVal = $('#divForCarat .carats_drop_down').val();
                    if (caratVal === "1.10")
                      tmpbezelimage =
                        watchSelect.bezelMetal === "18KY"
                          ? "MBY 52 1.10 SUP.png"
                          : "MBW 52 1.10 SUP.png";
                    else if (caratVal === "1.50")
                      tmpbezelimage =
                        watchSelect.bezelMetal === "18KY"
                          ? "MBY 50 1.50 SUP.png"
                          : "MBW 50 1.50 SUP.png";
                    else if (caratVal === "2.00")
                      tmpbezelimage =
                        watchSelect.bezelMetal === "18KY"
                          ? "MBY 44 2.00 SUP.png"
                          : "MBW 44 2.00 SUP.png";
                    else if (caratVal === "2.75")
                      tmpbezelimage =
                        watchSelect.bezelMetal === "18KY"
                          ? "MBY 40 2.75 SUP.png"
                          : "MBW 40 2.75 SUP.png";
                    //else
                    //    tmpbezelimage = re.dtWatch[0]["BezelImage"];
                    if (watchSelect.bezelMetal === "18KY")
                      tmpbezelMetal = watchSelect.bezelMetal;
                    else tmpbezelMetal = "18KW";
                  }
                  if (
                    watchSelect.bezelType === "Beadset" &&
                    watchSelect.bezelGemstoneShape === ""
                  ) {
                    tmpbezelimage =
                      watchSelect.bezelMetal === "18KY"
                        ? "MBY 52 1.10 SUP.png"
                        : "MBW 52 1.10 SUP.png";
                    if (watchSelect.bezelMetal === "18KY")
                      tmpbezelMetal = watchSelect.bezelMetal;
                    else tmpbezelMetal = "18KW";
                  }

                  if (
                    watchSelect.dialColor === "" &&
                    watchSelect.markerType === ""
                  ) {
                    if (watchSelect.braceletStyle !== "President") {
                      tmpdialimage =
                        watchSelect.braceletMetal === "18KW"
                          ? "Original Stick Dial White  ws.png"
                          : "Original Stick Dial White  ys.png";
                    } else {
                      tmpdialimage =
                        watchSelect.braceletMetal === "18KW"
                          ? "36mm-Day-Date-Original-Stick-Silver-ws.png"
                          : "36mm-Day-Date-Original-Stick-White-ys.png";
                    }
                  } else if (
                    watchSelect.markerType.toLowerCase() === "stick" &&
                    watchSelect.dialColor === ""
                  ) {
                    if (watchSelect.braceletStyle !== "President") {
                      tmpdialimage =
                        watchSelect.braceletMetal === "18KW"
                          ? "Original Stick Dial White  ws.png"
                          : "Original Stick Dial White  ys.png";
                    } else {
                      tmpdialimage =
                        watchSelect.braceletMetal === "18KW"
                          ? "36mm-Day-Date-Original-Stick-Silver-ws.png"
                          : "36mm-Day-Date-Original-Stick-White-ys.png";
                    }
                  }
                }
                //// ================================== End ======================================//
                ImagePath =
                  normalURL +
                  "WatchBuilderImage.aspx?Bracelet=" +
                  tmpbraceletimage +
                  "&Bezel=" +
                  tmpbezelimage +
                  "&Dial=" +
                  tmpdialimage +
                  "&Hands=" +
                  tmpbezelMetal +
                  "&wi=281&hi=418";
                BraceletImage = tmpbraceletimage;
                BezelImage = tmpbezelimage;
                DialImage = tmpdialimage;
                setWatchImg(ImagePath);
              } else {
                setWatchDescription("");
              }
            } else {
              console.log("Error");
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleWatchbuilderByGfinventoryID = (GFId, dealerId) => {
    try {
      debugger;
      var caratvalueforLoad = window.location.href.split("Ct=")[1];
      if (caratvalueforLoad === undefined) {
        caratvalueforLoad = "0";
      }
      const initialData = {
        // dealerLink: "720",//watchSelect.dealerLink !== "" ? watchSelect.dealerLink : "",
        // iDGFI: id,
        // dealerLinkGFI: "3075",
        // caratGFI: caratvalueforLoad,
        // additionalInfo: "WatchBuilder",
        // carat: "",
        // watchSize: "26mm",
        // braceletStyle: "",
        // braceletMetal: "",
        // bezelType: "",
        // bezelMetal: "",
        // bezelGemstoneType: "",
        // bezelGemstoneShape: "",
        // dialType: "",
        // markerType: "",
        // markerColor: "",
        // dialColor: "",
        // bezelDiamondQuality: "",
        // diamondMarkerType: "",
        // lastSelect: "",
        // bezelDiamondQuality: "",

        dealerLink: dealerId,
        additionalInfo: "WatchBuilder",
        watchSize: "26mm",
        braceletStyle: "",
        braceletMetal: "",
        bezelType: "",
        bezelMetal: "",
        bezelGemstoneType: "",
        bezelGemstoneShape: "",
        dialType: "",
        markerType: "",
        markerColor: "",
        dialColor: "",
        bezelDiamondQuality: "",
        diamondMarkerType: "",
        lastSelect: "",
        carat: caratvalueforLoad,
        dealerLinkGFI: "3075",
        iDGFI: GFId,
        caratGFI: "1.50",
      };

      WatchCustomizerService.GetWatchBuilderByGfinventoryID(initialData).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            console.log(responseData);
            if (responseData && Object.keys(responseData).length !== 0) {
              var caratVal = "";
              var BezelGemstones = "";
              var GfInventoryId = "";
              var WatchSize = "";
              var BraceletStyle = "";
              var BraceletMetal = "";
              var DialType = "";
              var DialColor = "";
              var MarkerType = "";
              var MarkerColor = "";
              var BezelType = "";
              var BezelGemstones = "";
              var BezelMetal = "";
              var BezelDiamondQuality = "";
              var BezelGemstoneShape = "";
              setDataGFInventory(responseData);
              setTemp1(responseData);
              selectCaratdropdown("bezelType");
              BezelGemstones =
                responseData.dtFromGFInventoryGFI[0]["BezelGemstones"];
              GfInventoryId = responseData.dtFromGFInventoryGFI[0]["ID"];
              WatchSize = responseData.dtFromGFInventoryGFI[0]["WatchSize"];
              BraceletStyle =
                responseData.dtFromGFInventoryGFI[0]["Bracelet Style"];
              BraceletMetal =
                responseData.dtFromGFInventoryGFI[0]["Bracelet Metal"];
              DialType = responseData.dtFromGFInventoryGFI[0]["DialType"];
              DialColor = responseData.dtFromGFInventoryGFI[0]["DialColor"];
              MarkerType = responseData.dtFromGFInventoryGFI[0]["Marker Type"];
              MarkerColor = responseData.dtFromGFInventoryGFI[0]["MarkerColor"];
              BezelType = responseData.dtFromGFInventoryGFI[0]["BezelType"];
              BezelGemstones =
                responseData.dtFromGFInventoryGFI[0]["BezelGemstones"];
              BezelMetal = responseData.dtFromGFInventoryGFI[0]["Bezel Metal"];
              BezelDiamondQuality =
                responseData.dtFromGFInventoryGFI[0]["Bezel Diamond Quality"];
              BezelGemstoneShape =
                responseData.dtFromGFInventoryGFI[0]["Bezel Gemstone Shape"];

              setWatchSelectGFInventory((prevState) => ({
                ...prevState,
                watchSize: WatchSize,
                braceletStyle: BraceletStyle,
                braceletMetal: BraceletMetal,
                bezelType: BezelType,
                bezelMetal: BezelMetal,
                bezelGemstoneType: BezelGemstones,
                bezelGemstoneShape: BezelGemstoneShape,
                dialType: DialType,
                dialColor: DialColor,
                markerType: MarkerType,
                markerColor: MarkerColor,
                bezelDiamondQuality: BezelDiamondQuality,
              }));

              var desc = "";
              desc = responseData.dtFromGFInventoryGFI[0]["ProductDescription"];
              setWatchDescription(desc);
              var bezelimg = "";
              if (
                WatchSize === "31mm" &&
                caratVal === "1.50" &&
                BezelMetal === "18KW"
              ) {
                bezelimg = "Mid-Size 1.50 SUP W.png";
              } else if (
                WatchSize === "31mm" &&
                caratVal === "0.90" &&
                BezelMetal === "18KW"
              )
                bezelimg = "Mid-Size .90 SUP W.png";
              else if (
                WatchSize === "31mm" &&
                caratVal === "1.50" &&
                BezelMetal === "18KY"
              )
                bezelimg = "Mid-Size 1.50 SUP Y.png";
              else if (
                WatchSize === "31mm" &&
                caratVal === "0.90" &&
                BezelMetal === "18KY"
              )
                bezelimg = "Mid-Size .90 SUP Y.png";
              else
                bezelimg = responseData.dtFromGFInventoryGFI[0]["BezelImage"];

              if (WatchSize === "36mm") {
                if (
                  BezelType === "Channel" &&
                  BezelGemstoneShape === "Round" &&
                  BezelMetal === "18KW"
                )
                  bezelimg =
                    caratVal === "1.50"
                      ? "MCW 1.50 SUP.png"
                      : "MCW 2.50 SUP.png";
                else if (
                  BezelType === "Channel" &&
                  BezelGemstoneShape === "Round" &&
                  BezelMetal === "18KY"
                ) {
                  bezelimg =
                    caratVal === "1.50"
                      ? "MCY 1.50 SUP.png"
                      : "MCY 2.50 SUP.png";
                }

                if (BezelType === "Beadset" && BezelGemstoneShape === "Round") {
                  if (caratVal === "1.10")
                    bezelimg =
                      BezelMetal === "18KW"
                        ? "MBW 52 1.10 SUP.png"
                        : "MBY 52 1.10 SUP.png";
                  else if (caratVal === "1.50")
                    bezelimg =
                      BezelMetal === "18KW"
                        ? "MBW 50 1.50 SUP.png"
                        : "MBY 50 1.50 SUP.png";
                  else if (caratVal === "2.00")
                    bezelimg =
                      BezelMetal === "18KW"
                        ? "MBW 44 2.00 SUP.png"
                        : "MBY 44 2.00 SUP.png";
                  else if (caratVal === "2.75")
                    bezelimg =
                      BezelMetal === "18KW"
                        ? "MBW 40 2.75 SUP.png"
                        : "MBY 40 2.75 SUP.png";
                }
              }

              if (WatchSize === "26mm") {
                if (BezelType === "Channel") bezelimg = "LCW 1.50 SUP.png";
                if (
                  BezelType === "Channel" &&
                  BezelGemstoneShape === "Round" &&
                  BezelMetal === "18KW"
                )
                  bezelimg =
                    caratVal === "1.50"
                      ? "LCW 1.50 SUP.png"
                      : "LCW 1.00 SUP.png";
                else if (
                  BezelType === "Channel" &&
                  BezelGemstoneShape === "Round" &&
                  BezelMetal === "18KY"
                ) {
                  bezelimg =
                    caratVal === "1.50"
                      ? "LCY 1.50 SUP.png"
                      : "LCY 1.00 SUP.png";
                }

                if (BezelType === "Beadset") bezelimg = "LBW 40 .75 SUP.png";
                if (BezelType === "Beadset" && BezelGemstoneShape === "Round") {
                  if (caratVal === "0.75" || caratVal === ".75")
                    bezelimg =
                      BezelMetal === "18KY"
                        ? "LBY 40 .75 SUP.png"
                        : "LBW 40 .75 SUP.png";
                  else if (caratVal === "1.50")
                    bezelimg =
                      BezelMetal === "18KY"
                        ? "LBY 32 1.50 SUP.png"
                        : "LBW 32 1.50 SUP.png";
                  else if (caratVal === "1.00")
                    bezelimg =
                      BezelMetal === "18KY"
                        ? "LBY 38 1.00 SUP.png"
                        : "LBW 38 1.00 SUP.png";
                }
              }
              var BraceletImage = "";
              var DialImage = "";
              var ImagePath = "";
              var normalURL = "https://designers.Gemfind.net/";
              BraceletImage =
                responseData.dtFromGFInventoryGFI[0]["BraceletImage"];
              DialImage = responseData.dtFromGFInventoryGFI[0]["DialImage"];
              if (BezelMetal === undefined || BezelMetal.length === 0) {
                BezelMetal =
                  responseData.dtFromGFInventoryGFI[0]["Bezel Metal"];
              }

              ImagePath =
                normalURL +
                "WatchBuilderImage.aspx?Bracelet=" +
                responseData.dtFromGFInventoryGFI[0]["BraceletImage"] +
                "&Bezel=" +
                bezelimg +
                "&Dial=" +
                responseData.dtFromGFInventoryGFI[0]["DialImage"] +
                "&Hands=" +
                BezelMetal +
                "&wi=281&hi=418";
              setWatchImg(ImagePath);
            }
          } else {
            console.log("Error");
          }
        }
      );
    } catch (error) {
      console.log(error);
    }
  };

  const selectCaratdropdown = (field) => {
    try {
      var carat = "";
      var bezelGemstoneType = "";
      var bezelGemstoneShape = "";
      var bezelDiamondQuality = "";
      if (watchSelectGFInventory.watchSize === "26mm") {
        if (watchSelectGFInventory.bezelGemstoneShape === "Princess")
          carat = "2.00";
        else if (watchSelectGFInventory.bezelGemstoneShape === "Baguette")
          carat = "1.75";
        else if (watchSelectGFInventory.bezelGemstoneShape === "Trapezoid")
          carat = "2.00";
      }

      if (field === "Channel") {
        if (watchSelectGFInventory.bezelGemstoneShape === "") {
          bezelGemstoneType = "Diamond";
          bezelGemstoneShape = "Round";
        }
        if (
          watchSelectGFInventory.carat === "0.50" &&
          (watchSelectGFInventory.bezelMetal === "18KY" ||
            watchSelectGFInventory.bezelMetal === "18KW") &&
          watchSelectGFInventory.bezelGemstoneShape === "Diamond"
        ) {
          bezelDiamondQuality = "superior";
          //disableBezelDiamondQuality("superior");
        } else {
          //enableBezelDiamondQuality();
        }
      } else if (field === "Beadset") {
        if (
          watchSelectGFInventory.carat === "0.50" &&
          (watchSelectGFInventory.bezelMetal === "18KY" ||
            watchSelectGFInventory.bezelMetal === "18KW") &&
          watchSelectGFInventory.bezelGemstoneShape === "Diamond"
        ) {
          bezelDiamondQuality = "superior";
          // disableBezelDiamondQuality();
          // var sript = $("#lisuperior").attr("onClick").replace('selectBezelDiamondQualitys', 'selectBezelDiamondQuality');
          // $("#liValue").attr("onClick", sript);
          // $("#liValue").children('div').removeClass("disable");
          // $("#liValue").children('li.style').removeClass("disable");
        } else {
          //enableBezelDiamondQuality();
        }
      }
      if (
        watchSelectGFInventory.watchSize === "31mm" &&
        watchSelectGFInventory.carat === "0"
      )
        carat = "0.90";
      //parent.location.hash = '&ID=' + GfInventoryId + '&Ct=' + caratValue;
      var BezelImage = "";
      var ImagePath = "";
      let normalURL = "https://designers.Gemfind.net/";
      var BraceletImage =
        dataGFInventory &&
          dataGFInventory.dtFromGFInventoryGFI[0].BraceletImage !== ""
          ? dataGFInventory.dtFromGFInventoryGFI[0].BraceletImage
          : "";
      var DialImage =
        dataGFInventory &&
          dataGFInventory.dtFromGFInventoryGFI[0].DialImage !== ""
          ? dataGFInventory.dtFromGFInventoryGFI[0].DialImage
          : "";
      if (watchSelectGFInventory.watchSize === "31mm") {
        if (watchSelectGFInventory.bezelMetal === "18KW")
          BezelImage =
            watchSelectGFInventory.carat === "1.50"
              ? "Mid-Size 1.50 SUP W.png"
              : "Mid-Size .90 SUP W.png";
        else if (watchSelectGFInventory.bezelMetal === "18KY")
          BezelImage =
            watchSelectGFInventory.carat === "1.50"
              ? "Mid-Size 1.50 SUP Y.png"
              : "Mid-Size .90 SUP Y.png";

        ImagePath =
          normalURL +
          "WatchBuilderImage.aspx?Bracelet=" +
          BraceletImage +
          "&Bezel=" +
          BezelImage +
          "&Dial=" +
          DialImage +
          "&Hands=" +
          watchSelectGFInventory.bezelMetal +
          "&wi=281&hi=418";
        setWatchImg(ImagePath);
      }
      //=========================== 36mm Size ===============================//
      if (watchSelectGFInventory.watchSize === "36mm") {
        if (
          watchSelectGFInventory.bezelType === "Channel" &&
          watchSelectGFInventory.bezelGemstoneShape === "Round" &&
          watchSelectGFInventory.bezelMetal === "18KW"
        )
          BezelImage =
            watchSelectGFInventory.carat === "1.50"
              ? "MCW 1.50 SUP.png"
              : "MCW 2.50 SUP.png";
        else if (
          watchSelectGFInventory.bezelType === "Channel" &&
          watchSelectGFInventory.bezelGemstoneShape === "Round" &&
          watchSelectGFInventory.bezelMetal === "18KY"
        ) {
          BezelImage =
            watchSelectGFInventory.carat === "1.50"
              ? "MCY 1.50 SUP.png"
              : "MCY 2.50 SUP.png";
        }

        if (
          watchSelectGFInventory.bezelType === "Beadset" &&
          watchSelectGFInventory.bezelGemstoneShape === "Round"
        ) {
          if (watchSelectGFInventory.carat === "1.10")
            BezelImage =
              watchSelectGFInventory.bezelMetal === "18KY"
                ? "MBY 52 1.10 SUP.png"
                : "MBW 52 1.10 SUP.png";
          else if (watchSelectGFInventory.carat === "1.50")
            BezelImage =
              watchSelectGFInventory.bezelMetal === "18KY"
                ? "MBY 50 1.50 SUP.png"
                : "MBW 50 1.50 SUP.png";
          else if (watchSelectGFInventory.carat === "2.00")
            BezelImage =
              watchSelectGFInventory.bezelMetal === "18KY"
                ? "MBY 44 2.00 SUP.png"
                : "MBW 44 2.00 SUP.png";
          else if (watchSelectGFInventory.carat === "2.75")
            BezelImage =
              watchSelectGFInventory.bezelMetal === "18KY"
                ? "MBY 40 2.75 SUP.png"
                : "MBW 40 2.75 SUP.png";
        }

        ImagePath =
          normalURL +
          "WatchBuilderImage.aspx?Bracelet=" +
          BraceletImage +
          "&Bezel=" +
          BezelImage +
          "&Dial=" +
          DialImage +
          "&Hands=" +
          watchSelectGFInventory.bezelMetal +
          "&wi=281&hi=418";
      }
      //================================== End =================================//
      //================================== 26mm Size =================================//
      if (watchSelectGFInventory.watchSize === "26mm") {
        if (
          watchSelectGFInventory.bezelType === "Channel" &&
          watchSelectGFInventory.bezelGemstoneShape === "Round" &&
          watchSelectGFInventory.bezelMetal === "18KW"
        )
          BezelImage =
            watchSelectGFInventory.carat === "1.50"
              ? "LCW 1.50 SUP.png"
              : "LCW 1.00 SUP.png";
        else if (
          watchSelectGFInventory.bezelType === "Channel" &&
          watchSelectGFInventory.bezelGemstoneShape === "Round" &&
          watchSelectGFInventory.bezelMetal === "18KY"
        ) {
          BezelImage =
            watchSelectGFInventory.carat === "1.50"
              ? "LCY 1.50 SUP.png"
              : "LCY 1.00 SUP.png";
        }
        if (watchSelectGFInventory.bezelType === "Beadset") {
          if (watchSelectGFInventory.carat === "0.50") {
            //================================== New =================================//
            var DiamondQuality = "Value";
            // selectBezelDiamondQuality('liValue', 1);
            //================================== New =================================//
          }
        }
        if (
          watchSelectGFInventory.bezelType === "Beadset" &&
          watchSelectGFInventory.bezelGemstoneShape === "Round"
        ) {
          if (
            watchSelectGFInventory.carat === "0.75" ||
            watchSelectGFInventory.carat === ".75"
          ) {
            BezelImage =
              watchSelectGFInventory.bezelMetal === "18KY"
                ? "LBY 40 .75 SUP.png"
                : "LBW 40 .75 SUP.png";
          } else if (watchSelectGFInventory.carat === "1.50")
            BezelImage =
              watchSelectGFInventory.bezelMetal === "18KY"
                ? "LBY 32 1.50 SUP.png"
                : "LBW 32 1.50 SUP.png";
          else if (watchSelectGFInventory.carat === "1.00")
            BezelImage =
              watchSelectGFInventory.bezelMetal === "18KY"
                ? "LBY 38 1.00 SUP.png"
                : "LBW 38 1.00 SUP.png";
        }

        //change on 21-feb  by chetan
        //EnableDisableDiamondQualityOptionByCarat(caratValue);

        ImagePath =
          normalURL +
          "WatchBuilderImage.aspx?Bracelet=" +
          BraceletImage +
          "&Bezel=" +
          BezelImage +
          "&Dial=" +
          DialImage +
          "&Hands=" +
          watchSelectGFInventory.bezelMetal +
          "&wi=281&hi=418";

        setWatchSelectGFInventory((prevState) => ({
          ...prevState,
          carat: carat,
          bezelGemstoneType: bezelGemstoneType,
          bezelGemstoneShape: bezelGemstoneShape,
          bezelDiamondQuality: bezelDiamondQuality,
        }));
      }
    } catch (e) {
      console.log(e);
    }
  };

  const watchSizeSpecification = () => {
    // debugger
    var braceletMetalSpan1 = "";
    var watchSize = "";
    var braceletStyle = "";
    var braceletMetal = "";
    if (initialGFFlag) {
      //&& watchSelectGFInventory.watchSize.length > 0
      watchSize = watchSelectGFInventory.watchSize;
      braceletStyle = watchSelectGFInventory.braceletStyle;
      braceletMetal = watchSelectGFInventory.braceletMetal;
    } else {
      watchSize = watchSelect.watchSize;
      braceletStyle = watchSelect.braceletStyle;
      braceletMetal = watchSelect.braceletMetal;
    }
    if (watchSize.length > 0) braceletMetalSpan1 = watchSize + " | ";
    if (braceletStyle.length > 0)
      braceletMetalSpan1 +=
        (braceletStyle !== "" ? "Original " : "") + braceletStyle + " | ";
    if (braceletMetal.length > 0)
      // braceletMetalSpan1 += braceletMetal.toUpperCase() + " | ";
      braceletMetalSpan1 += braceletMetal.charAt(0).toUpperCase() + braceletMetal.slice(1);
    // if (braceletMetalSpan1.length > 0) {
    //   braceletMetalSpan1 = braceletMetalSpan1.substr(
    //     0,
    //     braceletMetalSpan1.length - 2
    //   );
    // }
    return braceletMetalSpan1;
  };
  const bezelTypeSpecification = () => {
    debugger;
    var hBezelType = "";
    var bezelType = "";
    var hBezelMetal = "";
    var bezelMetal = "";
    var hBezelGemstoneType = "";
    var bezelGemstoneType = "";
    var hBezelGemstoneShape = "";
    var bezelGemstoneShape = "";
    var hBezelDiamondQuality = "";
    var bezelDiamondQuality = "";
    var weight = "";
    var caratVal = "";
    if (initialGFFlag) {
      //&& watchSelectGFInventory.watchSize.length > 0
      bezelType = watchSelectGFInventory.bezelType;
      bezelMetal = watchSelectGFInventory.bezelMetal;
      bezelGemstoneType = watchSelectGFInventory.bezelGemstoneType;
      bezelGemstoneShape = watchSelectGFInventory.bezelGemstoneShape;
      bezelDiamondQuality = watchSelectGFInventory.bezelDiamondQuality;
      caratVal = watchSelectGFInventory.carat;
    } else {
      bezelType = watchSelect.bezelType;
      bezelMetal = watchSelect.bezelMetal;
      bezelGemstoneType = watchSelect.bezelGemstoneType;
      bezelGemstoneShape = watchSelect.bezelGemstoneShape;
      bezelDiamondQuality = watchSelect.bezelDiamondQuality;
      caratVal = watchSelect.carat;
    }

    if (bezelType !== "" && bezelType !== "Fluted" && bezelType !== "Smooth")
      caratVal === undefined || caratVal === ""
        ? (weight = "")
        : (weight = caratVal + "ct ");
    if (bezelType !== "") {
      hBezelType =
        bezelType === "Fluted"
          ? bezelType + " " + "Original"
          : bezelType === "Smooth"
            ? bezelType + " " + "Original"
            : bezelType + " Custom";
    }
    if (bezelMetal !== "") {
      hBezelMetal = " | " + bezelMetal.toUpperCase();
    }
    if (bezelGemstoneType !== "") {
      hBezelGemstoneType = " | " + bezelGemstoneType;
    }
    if (bezelGemstoneShape !== "") {
      hBezelGemstoneShape = " | " + weight + bezelGemstoneShape;
    }
    if (bezelDiamondQuality !== "") {
      hBezelDiamondQuality = " | " + bezelDiamondQuality;
    }
    var bezelTypeSpan =
      hBezelType +
      hBezelMetal +
      hBezelGemstoneType +
      hBezelGemstoneShape +
      hBezelDiamondQuality;
    // if (bezelTypeSpan.length > 0) {
    //   bezelTypeSpan = bezelTypeSpan.substr(0, bezelTypeSpan.length - 2);
    // }
    return bezelTypeSpan;
  };

  const dialTypeSpecification = () => {
    var hDialType = "";
    var hMarkerType = "";
    var hDialColor = "";
    var dialType = "";
    var markerType = "";
    var dialColor = "";

    if (initialGFFlag) {
      //&& watchSelectGFInventory.watchSize.length > 0
      dialType = watchSelectGFInventory.dialType;
      markerType = watchSelectGFInventory.markerType;
      dialColor = watchSelectGFInventory.dialColor;
    } else {
      dialType = watchSelect.dialType;
      markerType = watchSelect.markerType;
      dialColor = watchSelect.dialColor;
    }

    if (dialType !== "") {
      hDialType = dialType === "Original DateJust 6917" ? "DateJust | " : dialType + " | ";
    }

    if (markerType !== "") {
      if (markerType === "Stick") {
        hMarkerType = "Original Stick";
      } else if (markerType === "Roman Numeral") {
        hMarkerType = "Original Roman Numeral";
      } else if (markerType === "Diamond") {
        hMarkerType = "Custom Diamond";
      } else if (markerType === "Ruby") {
        hMarkerType = "Custom MOP Ruby";
      } else if (markerType === "Emerald") {
        hMarkerType = "Custom MOP Emerald";
      } else if (markerType === "Sapphire") {
        hMarkerType = "Custom MOP Sapphire";
      } else if (markerType === "Plain") {
        hMarkerType = "Custom MOP Plain";
      } else if (markerType === "Custom MOP Dial") {
        hMarkerType = "Custom MOP Diamond";
      } else if (markerType === "Custom Stone Dial") {
        hMarkerType = "Custom Stone Diamond";
      } else if (markerType === "Custom Stone Plain") {
        hMarkerType = "Custom Stone Plain";
      } else if (markerType === "Custom Stone Roman Numeral") {
        hMarkerType = "Custom Stone Roman Numeral";
      } else if (markerType === "Custom MOP Roman Numeral") {
        hMarkerType = "Custom MOP Roman Numeral";
      } else if (markerType === "Custom MOP Diamond") {
        hMarkerType = "Custom Mop 8 & 2 Diamond";
      } else if (markerType === "Custom Baguette Diamond") {
        hMarkerType = "Custom Baguette Diamond";
      } else if (markerType === "Custom Elliptical Diamond") {
        hMarkerType = "Custom Elliptical Diamond";
      } else if (markerType === "Custom Annular Diamond") {
        hMarkerType = "Custom Annular Diamond";
      } else if (markerType === "Custom Diamond") {
        hMarkerType = "Custom 8 & 2 Diamond";
      } else if (markerType === "Custom Pave Diamond") {
        hMarkerType = "Custom Pave Diamond";
      } else {
        hMarkerType = "Stick";
      }
    }
    if (dialColor !== "") {
      hDialColor =
        " | " + dialColor === "Majenta" ? "Magenta" : " | " + dialColor;
    }

    var dialTypeSpan = hDialType + " " + hMarkerType + " " + hDialColor;
    // if (dialTypeSpan.length > 0) {
    //   dialTypeSpan = dialTypeSpan.substr(0, dialTypeSpan.length - 2);
    // }
    return dialTypeSpan;
  };

  const handlePrint = () => {
    window.print();
  };

  const watchSelectHandler = (name, value, arrName) => {
    if (watchSelect[name] !== value) {
      setSelectType((prevState) => ({
        ...prevState,
        type: arrName,
        sameValue: false,
      }));
      setWatchSelect((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    } else {
      value = "";
      setSelectType((prevState) => ({
        ...prevState,
        type: arrName,
        sameValue: true,
      }));
      setWatchSelect((prevState) => ({
        ...prevState,
        [name]: value,
      }));
    }
    if (initialLoad && name !== "watchSize") {
      setInitialLoad(false);
    }
    if (arrName === "dtWatchSize") {
      disableAllExceptWatchSize();
    } else if (arrName === "dtBraceletType") {
      disableAllExceptBraceletType();
    } else if (arrName === "dtBraceletMetal") {
      disableAllExceptBraceletMetal();
    } else if (arrName === "dtBezelType") {
      disableAllExceptBezelType();
    } else if (arrName === "dtBezelMetal") {
      disableAllExceptBezelMetal();
    } else if (arrName === "dtBezelGemstones") {
      disableAllExceptBezelGemstones();
    } else if (arrName === "dtBezelGemstoneShape") {
      disableAllExceptBezelGemstoneShape();
    } else if (arrName === "dtBezelDiamondQuality") {
      disableAllExceptBezelDiamondQuality();
    } else if (arrName === "dtDialType") {
      disableAllExceptDialType();
    } else if (arrName === "dtMarkerType") {
      disableAllExceptMarkerType();
    } else if (arrName === "dtDialColor") {
      disableAllExceptDialColor();
    }
    if (watchSelect.watchSize === "26mm" || watchSelect.watchSize === "31mm") {
      if (watchSelect.bezelType === "Channel" || watchSelect.bezelType === "") {
        setShowGemWt(false);
      } else if (watchSelect.bezelType === "Beadset") {
        if (
          watchSelect.bezelGemstoneType !== "Diamond" &&
          watchSelect.bezelGemstoneType !== ""
        ) {
          setShowGemWt(true);
        } else {
          setShowGemWt(false);
        }
      } else if (watchSelect.bezelType === "Pave") {
        setShowGemWt(false);
      } else {
        setShowGemWt(false);
      }
    }
  };
  const watchDropSelectHandler = (e, name, arrName) => {
    setSelectType((prevState) => ({
      ...prevState,
      type: arrName,
      sameValue: false,
    }));
    setWatchSelect((prevState) => ({
      ...prevState,
      [name]: e,
    }));

    if (arrName === "dtWatchSize") {
      disableAllExceptWatchSize();
    } else if (arrName === "dtBraceletType") {
      disableAllExceptBraceletType();
    } else if (arrName === "dtBraceletMetal") {
      disableAllExceptBraceletMetal();
    } else if (arrName === "dtBezelType") {
      disableAllExceptBezelType();
    } else if (arrName === "dtBezelMetal") {
      disableAllExceptBezelMetal();
    } else if (arrName === "dtBezelGemstones") {
      disableAllExceptBezelGemstones();
    } else if (arrName === "dtBezelGemstoneShape") {
      disableAllExceptBezelGemstoneShape();
    } else if (arrName === "dtBezelDiamondQuality") {
      disableAllExceptBezelDiamondQuality();
    } else if (arrName === "dtDialType") {
      disableAllExceptDialType();
    } else if (arrName === "dtMarkerType") {
      disableAllExceptMarkerType();
    } else if (arrName === "dtDialColor") {
      disableAllExceptDialColor();
    }
  };
  const requestInfoHandler = (e) => {
    const { name, value } = e.target;
    setRequestInfo({
      ...requestInfo,
      [name]: value,
      imagePath: watchImg,
      productID: watchReqData.dtWatch[0].ID.toString(),
      dealerID: watchSelect.dealerLink,
      detailURL:`https://gemwtchcustmizer.brainvire.net/?DealerLink=${watchSelect.dealerLink}&Track=0`
    });
    setReqValidations((prevdata) => ({
      ...prevdata,
      [name]: "",
    }));
  };

  const dropHintChangeHandler = (e) => {
    const { name, value } = e.target;
    setDropHint((prevState) => ({
      ...prevState,
      [name]: value,
      productID: watchReqData.dtWatch[0].ID.toString(),
      watchSize: watchSelect.watchSize,
      imagePath: watchImg,
      dealerID: watchSelect.dealerLink,
      detailURL:`https://gemwtchcustmizer.brainvire.net/?DealerLink=${watchSelect.dealerLink}&Track=0`
    }));
    setHintValidations((prevdata) => ({
      ...prevdata,
      [name]: "",
    }));
  };
  // const onDateChange = (dates, dateStrings) => {
  //   debugger
  //   if (dates) {
  //     setDropHint((prevState) => ({
  //       ...prevState,
  //       hintRecptGiftDeadline: dateStrings,
  //     }));
  //   } else {
  //     console.log("Clear");
  //   }
  // };
  const onDateChange = (e, name) => {
    debugger;
    setDropHint((prevState) => ({
      ...prevState,
      [name]: e.target.value,
    }));
  };
  const emailFriendHandler = (e) => {
    const { name, value } = e.target;
    setEmailFriend({
      ...emailFriend,
      [name]: value,
      productID: watchReqData.dtWatch[0].ID.toString(),
      imagePath: watchImg,
      dealerID: watchSelect.dealerLink,
    });
    setEmailFrenValidations((prevdata) => ({
      ...prevdata,
      [name]: "",
    }));
  };
  const scheduleViewHandler = (e, type) => {
    var value = "";
    value = e.target.value;
    setSchedule({
      ...schedule,
      [type]: value,
    });
    setScheduleValidations((prevdata) => ({
      ...prevdata,
      [type]: "",
    }));
  };
  const scheduleViewHandlerDD = (e, type) => {
    var value = "";
    value = e;
    setSchedule({
      ...schedule,
      [type]: value,
      dealerID: watchSelect.dealerLink,
    });
    setScheduleValidations((prevdata) => ({
      ...prevdata,
      [type]: "",
    }));
  };
  // const onScheduleDateChange = (dates, dateStrings) => {
  //   if (dates) {
  //     setSchedule((prevState) => ({
  //       ...prevState,
  //       appDate: dateStrings,
  //       did: DidGFInventoryID ? DidGFInventoryID : "",
  //       strDiamondLinkURL: DiamondLinkURL ? DiamondLinkURL : "",
  //       shape: watchSelect.bezelGemstoneShape
  //         ? watchSelect.bezelGemstoneShape
  //         : "",
  //     }));
  //     setScheduleValidations((prevdata) => ({
  //       ...prevdata,
  //       appDate: "",
  //     }));
  //   } else {
  //     console.log("Clear");
  //   }
  // };
  const onScheduleDateChange = (e) => {
    debugger;
    if (e) {
      setSchedule((prevState) => ({
        ...prevState,
        appDate: e.target.value,
        did: DidGFInventoryID ? DidGFInventoryID : "",
        strDiamondLinkURL: DiamondLinkURL ? DiamondLinkURL : "",
        shape: watchSelect.bezelGemstoneShape
          ? watchSelect.bezelGemstoneShape
          : "",
      }));
      setScheduleValidations((prevdata) => ({
        ...prevdata,
        appDate: "",
      }));
    } else {
      console.log("Clear");
    }
  };
  // const scheduleSubmitHandler = () => {
  //   try {
  //     debugger;
  //     if (watchSelect.watchSize === "") {
  //       // alert('Requireddddddddddddddd', 'Please select watchsize.')
  //       NotificationManager.error("Please select Watch-Size.");
  //       return;
  //     }
  //     let isValid = true;

  //     var validEmailRegex =
  //       /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

  //     if (!schedule.email) {
  //       isValid = false;
  //       scheduleValidations.email = "Email is compulsory";
  //     } else if (!schedule.email.match(validEmailRegex)) {
  //       isValid = false;
  //       scheduleValidations.email = "Email address is invalid";
  //     } else {
  //       scheduleValidations.email = "";
  //     }
  //     if (!schedule.appType) {
  //       isValid = false;
  //       scheduleValidations.appType = "Visiting reason is compulsory";
  //     } else {
  //       scheduleValidations.appType = "";
  //     }
  //     if (!schedule.appTime) {
  //       isValid = false;
  //       scheduleValidations.appTime = "Time is compulsory";
  //     } else {
  //       scheduleValidations.appTime = "";
  //     }
  //     if (!schedule.appDate) {
  //       isValid = false;
  //       scheduleValidations.appDate = "Date is compulsory";
  //     } else {
  //       scheduleValidations.appDate = "";
  //     }
  //     if (!schedule.message) {
  //       isValid = false;
  //       scheduleValidations.message = "Personal message is compulsory";
  //     } else {
  //       scheduleValidations.message = "";
  //     }

  //     setScheduleValidations((prevState) => ({
  //       ...prevState,
  //       ...scheduleValidations,
  //     }));

  //     if (isValid) {
  //       WatchCustomizerService.GetScheduleAppointer(schedule).then(
  //         (response) => {
  //           let message = response.data.message;
  //           let responseData = response.data.responseData;
  //           if (message === "Success") {
  //             handleCancel5();
  //             NotificationManager.success("Mail Sent Successfully.");
  //             setSchedule((prevState) => ({
  //               ...prevState,
  //               appType: "",
  //               message: "",
  //               appDate: "",
  //               appTime: "",
  //               email: "",
  //               retailerID: "",
  //               dealerID: "",
  //               schedulPnone: "",
  //               did: "",
  //               shape: "",
  //               ctw: "",
  //               strDiamondLinkURL: "",
  //               step: "",
  //               themeName: "",
  //               loadFromMasterlink: true,
  //             }));
  //             console.log(schedule);
  //           } else {
  //             console.log("Error");
  //           }
  //         }
  //       );
  //     }
  //   } catch (e) {
  //     console.log(e);
  //   }
  // };
  const scheduleSubmitHandler = () => {
    try {
      debugger;
      if (watchSelect.watchSize === "") {
        // alert('Requireddddddddddddddd', 'Please select watchsize.')
        NotificationManager.error("Please select Watch-Size.");
        return;
      }
      let isValid = true;

      var validEmailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!schedule.email) {
        isValid = false;
        scheduleValidations.email = "Email is compulsory";
      } else if (!schedule.email.match(validEmailRegex)) {
        isValid = false;
        scheduleValidations.email = "Email address is invalid";
      } else {
        scheduleValidations.email = "";
      }
      if (!schedule.appType) {
        isValid = false;
        scheduleValidations.appType = "Visiting reason is compulsory";
      } else {
        scheduleValidations.appType = "";
      }
      if (!schedule.appTime) {
        isValid = false;
        scheduleValidations.appTime = "Time is compulsory";
      } else {
        scheduleValidations.appTime = "";
      }
      if (!schedule.appDate) {
        isValid = false;
        scheduleValidations.appDate = "Date is compulsory";
      } else {
        scheduleValidations.appDate = "";
      }
      if (!schedule.message) {
        isValid = false;
        scheduleValidations.message = "Personal message is compulsory";
      } else {
        scheduleValidations.message = "";
      }

      setScheduleValidations((prevState) => ({
        ...prevState,
        ...scheduleValidations,
      }));
      let inputData = {
        ...schedule,
        appDate: schedule.appDate
          ? moment(schedule.appDate).format("MM/DD/YYYY")
          : "",
      };

      if (isValid) {
        WatchCustomizerService.GetScheduleAppointer(inputData).then(
          (response) => {
            let message = response.data.message;
            let responseData = response.data.responseData;
            if (message === "Success") {
              handleCancel5();
              NotificationManager.success("Mail Sent Successfully.");
              setSchedule((prevState) => ({
                ...prevState,
                appType: "",
                message: "",
                appDate: "",
                appTime: "",
                email: "",
                retailerID: "",
                dealerID: "",
                schedulPnone: "",
                did: "",
                shape: "",
                ctw: "",
                strDiamondLinkURL: "",
                step: "",
                themeName: "",
                loadFromMasterlink: true,
              }));
              console.log(schedule);
            } else {
              console.log("Error");
            }
          }
        );
      }
    } catch (e) {
      console.log(e);
    }
  };
  const requestSubmitHandler = () => {
    try {
      if (watchSelect.watchSize === "") {
        // alert('Required', 'Please select watchsize.')
        NotificationManager.error("Please select Watch-Size.");
        return;
      }
      let isValid = true;

      var validEmailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!requestInfo.yourName) {
        isValid = false;
        reqValidations.yourName = "Username is compulsory";
      } else {
        reqValidations.yourName = "";
      }
      if (!requestInfo.yourEmail) {
        isValid = false;
        reqValidations.yourEmail = "Email is compulsory";
      } else if (!requestInfo.yourEmail.match(validEmailRegex)) {
        isValid = false;
        reqValidations.yourEmail = "Email address is invalid";
      } else {
        reqValidations.yourEmail = "";
      }
      if (!requestInfo.yourCompName) {
        isValid = false;
        reqValidations.yourCompName = "Company name is compulsory";
      } else {
        reqValidations.yourCompName = "";
      }
      if (!requestInfo.yourPhone) {
        isValid = false;
        reqValidations.yourPhone = "Phone number is compulsory";
      } else {
        reqValidations.yourPhone = "";
      }
      if (!requestInfo.yourStorePhone) {
        isValid = false;
        reqValidations.yourStorePhone = "Store phone number is compulsory";
      } else {
        reqValidations.yourStorePhone = "";
      }
      if (!requestInfo.message) {
        isValid = false;
        reqValidations.message = "Personal message is compulsory";
      } else {
        reqValidations.message = "";
      }

      setReqValidations((prevState) => ({
        ...prevState,
        ...reqValidations,
      }));

      if (isValid) {
        WatchCustomizerService.GetWatchRequestInfo(requestInfo).then(
          (response) => {
            let message = response.data.message;
            let responseData = response.data.responseData;
            if (message === "Success") {
              handleCancel1();
              NotificationManager.success("Mail Sent Successfully.");
              setRequestInfo({
                yourName: "",
                yourEmail: "",
                yourPhone: "",
                message: "",
                dealerID: "",
                productID: "",
                detailURL: "",
                imagePath: "",
                carat: "",
                yourCompName: "",
                yourStorePhone: "",
                productDescription: "",
              });
            } else {
              console.log("Error");
            }
          }
        );
      }
    } catch (error) {
      console.log(error);
    }
  };
  const dropHintSubmitHandler = () => {
    try {
      if (watchSelect.watchSize === "") {
        // alert('Required', 'Please select watchsize.')
        NotificationManager.error("Please select Watch-Size.");
      }
      let isValid = true;

      var validEmailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!dropHint.recptName) {
        isValid = false;
        hintValidations.recptName = "Username is compulsory";
      } else {
        hintValidations.recptName = "";
      }
      if (!dropHint.recptEmail) {
        isValid = false;
        hintValidations.recptEmail = "Email is compulsory";
      } else if (!dropHint.recptEmail.match(validEmailRegex)) {
        isValid = false;
        hintValidations.recptEmail = "Email address is invalid";
      } else {
        hintValidations.recptEmail = "";
      }
      if (!dropHint.recptReason) {
        isValid = false;
        hintValidations.recptReason = "Reason is compulsory";
      } else {
        hintValidations.recptReason = "";
      }
      if (!dropHint.hintRecptGiftDeadline) {
        isValid = false;
        hintValidations.hintRecptGiftDeadline = "Deadline is compulsory";
      } else {
        hintValidations.hintRecptGiftDeadline = "";
      }
      if (!dropHint.recpMessage) {
        isValid = false;
        hintValidations.recpMessage = "Message is compulsory";
      } else {
        hintValidations.recpMessage = "";
      }

      setHintValidations((prevState) => ({
        ...prevState,
        ...hintValidations,
      }));

      if (isValid) {
        WatchCustomizerService.GetDropHintRequest(dropHint).then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            handleCancel2();
            NotificationManager.success("Mail Sent Successfully.");
            setDropHint((prevState) => ({
              ...prevState,
              dealerID: "",
              recptEmail: "",
              recptName: "",
              recptReason: "",
              recpMessage: "",
              hintRecptGiftDeadline: "",
              productID: "",
              detailURL:
                "https://designers.gemfind.net/WatchBuilder.aspx?DealerLink=720&ThemeName=DefaultTheme#&ID=41&Ct=0",
              themeName: "DefaultTheme",
              imagePath: "",
              watchSize: "",
            }));
            console.log(dropHint);
          } else {
            console.log("Error");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const emailFriendSubmitHandler = () => {
    try {
      if (watchSelect.watchSize === "") {
        // alert('Required', 'Please select watchsize.')
        NotificationManager.error("Please select Watch-Size.");
      }
      let isValid = true;

      var validEmailRegex =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

      if (!emailFriend.yourName) {
        isValid = false;
        emailFrenValidations.yourName = "Name is compulsory";
      } else {
        emailFrenValidations.yourName = "";
      }
      if (!emailFriend.yourMail) {
        isValid = false;
        emailFrenValidations.yourMail = "Email is compulsory";
      } else if (!emailFriend.yourMail.match(validEmailRegex)) {
        isValid = false;
        emailFrenValidations.yourMail = "Email address is invalid";
      } else {
        emailFrenValidations.yourMail = "";
      }
      if (!emailFriend.friendMail) {
        isValid = false;
        emailFrenValidations.friendMail = "Email is compulsory";
      } else if (!emailFriend.friendMail.match(validEmailRegex)) {
        isValid = false;
        emailFrenValidations.friendMail = "Email address is invalid";
      } else {
        emailFrenValidations.friendMail = "";
      }
      if (!emailFriend.subject) {
        isValid = false;
        emailFrenValidations.subject = "Subject is compulsory";
      } else {
        emailFrenValidations.subject = "";
      }

      if (!emailFriend.message) {
        isValid = false;
        emailFrenValidations.message = "Personal message is compulsory";
      } else {
        emailFrenValidations.message = "";
      }

      setReqValidations((prevState) => ({
        ...prevState,
        ...emailFrenValidations,
      }));

      if (isValid) {
        WatchCustomizerService.GetEmailAFriend(emailFriend).then((response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            handleCancel3();
            NotificationManager.success("Mail Sent Successfully.");
            setEmailFriend({
              yourName: "",
              yourMail: "",
              friendMail: "",
              message: "",
              dealerID: "",
              productID: "",
              detailURL: "",
              imagePath: "",
              carat: "",
              subject: "",
              productDescription: "",
            });
          } else {
            NotificationManager.error("Mail Not Sent.");
            console.log("Error");
          }
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const showModal1 = () => {
    setIsModalVisible1(true);
  };
  const handleCancel1 = () => {
    setIsModalVisible1(false);
  };
  const showModal2 = () => {
    setIsModalVisible2(true);
    console.log(dropHint);
  };
  const handleCancel2 = () => {
    setIsModalVisible2(false);
  };
  const showModal3 = () => {
    setIsModalVisible3(true);
  };
  const handleCancel3 = () => {
    setIsModalVisible3(false);
  };
  const showModal4 = () => {
    handlePrint();
    setIsModalVisible4(true);
  };
  const handleCancel4 = () => {
    setIsModalVisible4(false);
  };
  const showModal5 = () => {
    scheduleDataFromDBHandler();
    setIsModalVisible5(true);
  };
  const handleCancel5 = () => {
    setIsModalVisible5(false);
  };
  function onSearch(val) {
    console.log("search:", val);
  }
  function onChange(e) {
    console.log(`checked = ${e.target.checked}`);
  }
  const handleSwitch = () => {
    setswitchValue(!switchValue);
  };

  const scheduleDataFromDBHandler = () => {
    try {
      var inputData = {
        dealerLink: watchSelect.dealerLink,
        themeName: "WhiteThemme",
        appointDate: "",
      };
      WatchCustomizerService.GetDataForScheduling(inputData).then(
        (response) => {
          let message = response.data.message;
          let responseData = response.data.responseData;
          if (message === "Success") {
            console.log(responseData);
            setAppntmntDD(responseData);
          } else {
            console.log("Error");
          }
        }
      );
    } catch (e) {
      console.log(e);
    }
  };
  const disableAllExceptWatchSize = () => {
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableDialType();
    disableMarkerType();
    disableDialColor();
  };
  const disableBraceletStyle = () => {
    setBraceletStyleDisable((prevState) => ({
      ...prevState,
      Jubilee: true,
      Oyster: true,
      President: true,
    }));
  };
  const disableAllExceptBraceletType = () => {
    watchReqData.dtBraceletType.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setBraceletStyleDisable((prevState) => ({
          ...prevState,
          [item["Bracelet Style"]]: false,
        }));
      }
    });

    disableBraceletMetal();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableDialType();
    disableMarkerType();
    disableDialColor();
  };
  const disableBraceletMetal = () => {
    setBraceletMetalDisable((prevState) => ({
      ...prevState,
      "18KW": true,
      "18KY": true,
      "18KY/SS": true,
      "Stainless Steel": true,
    }));
  };
  const disableAllExceptBraceletMetal = () => {
    watchReqData.dtBraceletMetal.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setBraceletMetalDisable((prevState) => ({
          ...prevState,
          [item["Bracelet Metal"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableDialType();
    disableMarkerType();
    disableDialColor();
  };
  const disableBezelType = () => {
    setBezelTypeDisable((prevState) => ({
      ...prevState,
      Beadset: true,
      Channel: true,
      Fluted: true,
      Pave: true,
      Smooth: true,
    }));
  };
  const disableAllExceptBezelType = () => {
    watchReqData.dtBezelType.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setBezelTypeDisable((prevState) => ({
          ...prevState,
          [item["BezelType"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableDialType();
    disableMarkerType();
    disableDialColor();
  };
  const disableBezelMetal = () => {
    setBezelMetalDisable((prevState) => ({
      ...prevState,
      "18KW": true,
      "18KY": true,
      "Stainless Steel": true,
    }));
  };
  const disableAllExceptBezelMetal = () => {
    watchReqData.dtBezelMetal.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setBezelMetalDisable((prevState) => ({
          ...prevState,
          [item["Bezel Metal"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelType();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableDialType();
    disableMarkerType();
    disableDialColor();
  };
  const disableBezelGemstones = () => {
    setBezelGemstonesDisable((prevState) => ({
      ...prevState,
      Diamond: true,
      "Diamond & Emerald": true,
      "Diamond & Ruby": true,
      "Diamond & Sapphire": true,
    }));
  };
  const disableAllExceptBezelGemstones = () => {
    watchReqData.dtBezelGemstones.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setBezelGemstonesDisable((prevState) => ({
          ...prevState,
          [item["BezelGemstones"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableDialType();
    disableMarkerType();
    disableDialColor();
  };
  const disableBezelGemstoneShape = () => {
    setBezelGemstoneShapeDisable((prevState) => ({
      ...prevState,
      Baguette: true,
      Princess: true,
      Round: true,
      Trapezoid: true,
    }));
  };
  const disableAllExceptBezelGemstoneShape = () => {
    watchReqData.dtBezelGemstoneShape.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setBezelGemstoneShapeDisable((prevState) => ({
          ...prevState,
          [item["Bezel Gemstone Shape"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelDiamondQuality();
    disableDialType();
    disableMarkerType();
    disableDialColor();
  };
  const disableBezelDiamondQuality = () => {
    setBezelDiamondQualityDisable((prevState) => ({
      ...prevState,
      standard: true,
      superior: true,
      Value: true,
    }));
  };
  const disableAllExceptBezelDiamondQuality = () => {
    watchReqData.dtBezelDiamondQuality.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setBezelDiamondQualityDisable((prevState) => ({
          ...prevState,
          [item["Bezel Diamond Quality"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableDialType();
    disableMarkerType();
    disableDialColor();
  };
  const disableDialType = () => {
    setDialTypeDisable((prevState) => ({
      ...prevState,
      "Original DateJust 6917": true,
      "Day-Date": true,
    }));
  };
  const disableAllExceptDialType = () => {
    watchReqData.dtDialType.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setDialTypeDisable((prevState) => ({
          ...prevState,
          [item["DialType"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableMarkerType();
    disableDialColor();
  };
  const disableMarkerType = () => {
    setMarkerTypeDisable((prevState) => ({
      ...prevState,
      "Custom MOP Dial": true,
      "Custom MOP Diamond": true,
      "Custom MOP Roman Numeral": true,
      "Custom Stone Dial": true,
      "Custom Stone Plain": true,
      "Custom Stone Roman Numeral": true,
      Diamond: true,
      Emerald: true,
      Plain: true,
      "Roman Numeral": true,
      Ruby: true,
      Sapphire: true,
      Stick: true,
      "Custom Annular Diamond": true,
      "Custom Baguette Diamond": true,
      "Custom Diamond": true,
      "Custom Elliptical Diamond": true,
      "Custom Pave Diamond": true,
    }));
  };
  const disableAllExceptMarkerType = () => {
    watchReqData.dtMarkerType.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setMarkerTypeDisable((prevState) => ({
          ...prevState,
          [item["Marker Type"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableDialType();
    disableDialColor();
  };
  const disableDialColor = () => {
    setDialColorDisable((prevState) => ({
      ...prevState,
      Black: true,
      "Black MOP": true,
      Blue: true,
      Champagne: true,
      Chocolate: true,
      "Golden MOP": true,
      Lapis: true,
      Lavender: true,
      Majenta: true,
      Meteorite: true,
      Onyx: true,
      Pink: true,
      "Pink MOP": true,
      Red: true,
      Rose: true,
      Silver: true,
      White: true,
      "White MOP": true,
      Green: true,
      "Mink MOP": true,
      Pietersite: true,
    }));
  };
  const disableAllExceptDialColor = () => {
    watchReqData.dtDialColor.map((item, index) => {
      if (item.drpHTML !== "disabled") {
        setDialColorDisable((prevState) => ({
          ...prevState,
          [item["DialColor"]]: false,
        }));
      }
    });
    disableBraceletStyle();
    disableBraceletMetal();
    disableBezelType();
    disableBezelMetal();
    disableBezelGemstones();
    disableBezelGemstoneShape();
    disableBezelDiamondQuality();
    disableDialType();
    disableMarkerType();
  };

  const resetHandler = () => {
    setInitialLoad(true);
    setTemp1([]);
    setTemp2([]);
    setWatchSelect((prevState) => ({
      ...prevState,
      dealerLink: watchSelect.dealerLink,
      additionalInfo: "WatchBuilder",
      watchSize: "26mm",
      braceletStyle: "",
      braceletMetal: "",
      bezelType: "",
      bezelMetal: "",
      bezelGemstoneType: "",
      bezelGemstoneShape: "",
      dialType: "",
      markerType: "",
      markerColor: "",
      dialColor: "",
      bezelDiamondQuality: "",
      diamondMarkerType: "",
      lastSelect: "",
      carat: "",
    }));
  };

  // Date

  const disablePastDate = () => {
    const today = new Date();
    const dd = String(today.getDate()).padStart(2, "0");
    const mm = String(today.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = today.getFullYear();
    return yyyy + "-" + mm + "-" + dd;
  };

  return (
    <React.Fragment>
      <div className="prodetail__section form__fields p-0 border-0">
        <div className="col-md-12">
          <div className="prod__maindiv border-0 p-0">
            <div className="row">
              <div className="row">
                <div className="col-md-6">
                  <div className="heading__block">
                    <h4 className="workarea__heading mt-0">Watch Customizer</h4>
                  </div>
                </div>
                <div className="col-md-6 text-right text-left-mobile mobile__bottomspace">
                  <Button
                    type="primary"
                    onClick={resetHandler}
                    className="mr-2"
                  >
                    Reset
                  </Button>
                  <Button type="primary" onClick={showModal4} className="mr-2">
                    <i class="fa fa-print" aria-hidden="true"></i>
                  </Button>
                  <Button type="primary" onClick={showModal3}>
                    <i class="fa fa-envelope-o" aria-hidden="true"></i>
                  </Button>
                </div>
              </div>
              <div className="col-md-12 d-flex">
              </div>
              <div className="col-lg-3 col-md-3">
                <div className="prodimg__div">
                  <img src={watchImg} alt="watch builder" />
                  <div className="product-description">
                    <h4 className="subheading">Custom Pre-Owned Rolex Watch</h4>
                    <span className="watch-description">
                      {watchDescription}
                    </span>
                  </div>
                  <div className="serial-number">
                    <span className="">{`SKU# : ${watchReqData &&
                      watchReqData.dtWatch &&
                      watchReqData.dtWatch[0].StyleNumber
                      }`}</span>
                  </div>
                  <div className="request-info">
                    <div className="request-info-links">
                      <a href="#" onClick={showModal1}>
                        Request More Info
                      </a>
                      <a href="#" onClick={showModal2}>
                        Drop A Hint
                      </a>
                      {/*<a href="#" onClick={showModal3}>
                        E-Mail A Friend
                      </a>*/}
                      {/*<a href="#" onClick={showModal4}>
                        Print Details
                      </a>*/}
                      <a href="#" onClick={showModal5}>
                        Schedule Viewing
                      </a>
                    </div>
                    <p>
                      Have a question regarding this item? Our specialist are
                      available to assist you. | Call us: (800)373-4373
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-9 col-md-9">
                <div className="prodesc__div">
                  <Tabs
                    defaultActiveKey="ItemsDetails"
                    id="uncontrolled-tab-example"
                    className="tab__div mb-3"
                  >
                    <Tab eventKey="ItemsDetails" title="Items Details">
                      <div className="accordian__section">
                        <div className="header-text">
                          <h3 className="subheading">
                            Configure & Customize Your Pre-Owned Rolex Watch
                          </h3>
                        </div>

                        <Accordion defaultActiveKey="0">
                          <Accordion.Item eventKey="0">
                            <Accordion.Header>
                              <div className="accordian__headtext watchCustomizer-accordian-headtext">
                                <label>
                                  <span>STEP 1 | Size & Bracelet Type </span>
                                  {/* <span >{watchSizeSpecification()}</span> */}
                                  <span className="watchCustomizer-captilize-sp">
                                    {watchSizeSpecification()}
                                  </span>
                                </label>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row">
                                <div className="selection-title">
                                  <h5>Choose Watch Type</h5>
                                </div>
                                <div className={`selection__block row `}>
                                  <div
                                    className={`selection__box ${(!initialLoad && size36Disable) ||
                                      (initialGFFlag &&
                                        watchSelectGFInventory.watchSize !==
                                        "36mm")
                                      ? "disabledSec"
                                      : watchSelect.watchSize === "36mm"
                                        ? "selected"
                                        : ""
                                      } col-md-4 `}
                                    onClick={() =>
                                      watchSelectHandler(
                                        "watchSize",
                                        "36mm",
                                        "dtWatchSize"
                                      )
                                    }
                                  >
                                    <div className="watch-desc">
                                      <div className="category">
                                        <h4>Men's</h4>
                                      </div>
                                      <div className="size">
                                        <span>36mm</span>
                                      </div>
                                    </div>
                                    <div className="img-section">
                                      <img src={Watch1} alt="rolex" />
                                    </div>
                                  </div>
                                  <div
                                    className={`selection__box ${(!initialLoad && size26Disable) ||
                                      (initialGFFlag &&
                                        watchSelectGFInventory.watchSize !==
                                        "26mm ")
                                      ? "disabledSec"
                                      : watchSelect.watchSize === "26mm"
                                        ? "selected"
                                        : ""
                                      } col-md-4 `}
                                    onClick={() =>
                                      watchSelectHandler(
                                        "watchSize",
                                        "26mm",
                                        "dtWatchSize"
                                      )
                                    }
                                  >
                                    <div className="watch-desc">
                                      <div className="category">
                                        <h4>Ladies</h4>
                                      </div>
                                      <div className="size">
                                        <span>26mm</span>
                                      </div>
                                    </div>
                                    <div className="img-section">
                                      <img src={Watch2} alt="rolex" />
                                    </div>
                                  </div>
                                  <div
                                    className={`selection__box ${(!initialLoad && size31Disable) ||
                                      (initialGFFlag &&
                                        watchSelectGFInventory.watchSize !==
                                        "31mm")
                                      ? "disabledSec"
                                      : watchSelect.watchSize === "31mm"
                                        ? "selected"
                                        : ""
                                      } col-md-4 `}
                                    onClick={() =>
                                      watchSelectHandler(
                                        "watchSize",
                                        "31mm",
                                        "dtWatchSize"
                                      )
                                    }
                                  >
                                    <div className="watch-desc">
                                      <div className="category">
                                        <h4>Mid Size</h4>
                                      </div>
                                      <div className="size">
                                        <span>31mm</span>
                                      </div>
                                    </div>
                                    <div className="img-section">
                                      <img src={Watch3} alt="rolex" />
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-6">
                                  <div className="selection-title">
                                    <h5>Choose Bracelet Style</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtBraceletType.map(
                                        (item, index) => {
                                          if (
                                            Object.keys(item).length !== 0 &&
                                            Object.keys(item["Bracelet Style"])
                                              .length !== 0 &&
                                            Object.keys(item.img).length !== 0
                                          ) {
                                            return (
                                              <div
                                                className={`selection__box ${Object.keys(temp1).length !==
                                                  0 &&
                                                  braceletStyleDisable[
                                                  item["Bracelet Style"]
                                                  ] &&
                                                  temp1["dtBraceletType"][index]
                                                    .drpHTML === "disabled"
                                                  ? "disabledSec"
                                                  : watchSelect.braceletStyle ===
                                                    item["Bracelet Style"]
                                                    ? "selected"
                                                    : ""
                                                  } col-md-4`}
                                                onClick={() =>
                                                  watchSelectHandler(
                                                    "braceletStyle",
                                                    item["Bracelet Style"],
                                                    "dtBraceletType"
                                                  )
                                                }
                                                key={item["Bracelet Style"]}
                                              >
                                                <div
                                                  className={`img-section img-section-sp`}
                                                >
                                                  <img
                                                    src={item.img}
                                                    alt="rolex"
                                                  />
                                                </div>
                                                <div
                                                  className={`watch-desc-sm`}
                                                >
                                                  <h4>
                                                    {item["Bracelet Style"]}
                                                  </h4>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="selection-title">
                                    <h5>Choose Bracelet Metal</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtBraceletMetal.map(
                                        (item, index) => {
                                          if (
                                            Object.keys(item).length !== 0 &&
                                            Object.keys(item["Bracelet Metal"])
                                              .length !== 0 &&
                                            Object.keys(item.img).length !== 0
                                          ) {
                                            return (
                                              <div
                                                className={`selection__box ${Object.keys(temp1).length !==
                                                  0 &&
                                                  braceletMetalDisable[
                                                  item["Bracelet Metal"]
                                                  ] &&
                                                  temp1["dtBraceletMetal"][
                                                    index
                                                  ].drpHTML === "disabled"
                                                  ? "disabledSec"
                                                  : watchSelect.braceletMetal ===
                                                    item["Bracelet Metal"]
                                                    ? "selected"
                                                    : ""
                                                  } col-md-3`}
                                                onClick={() =>
                                                  watchSelectHandler(
                                                    "braceletMetal",
                                                    item["Bracelet Metal"],
                                                    "dtBraceletMetal"
                                                  )
                                                }
                                              >
                                                <div
                                                  className={`img-section img-section-sp`}
                                                >
                                                  <img
                                                    src={item.img}
                                                    alt="rolex"
                                                  />
                                                </div>
                                                <div
                                                  className={`watch-desc-sm`}
                                                >
                                                  <h4>
                                                    {item["Bracelet Metal"]}
                                                  </h4>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="1">
                            <Accordion.Header>
                              <div className="accordian__headtext watchCustomizer-accordian-headtext">
                                <label>
                                  <span>STEP 2 | Bezel Type</span>
                                  {/* <span>{bezelTypeSpecification()}</span> */}
                                  <span className="watchCustomizer-captilize-sp">
                                    {bezelTypeSpecification()}
                                  </span>
                                </label>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row mt-4">
                                <div className="col-md-6">
                                  <div className="selection-title">
                                    <h5>Choose Bezel Type</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtBezelType.map((item, index) => {
                                        if (
                                          Object.keys(item).length !== 0 &&
                                          Object.keys(item["BezelType"])
                                            .length !== 0 &&
                                          Object.keys(item.img).length !== 0
                                        ) {
                                          return (
                                            <div
                                              className={`selection__box ${Object.keys(temp1).length !==
                                                0 &&
                                                bezelTypeDisable[
                                                item["BezelType"]
                                                ] &&
                                                temp1["dtBezelType"][index]
                                                  .drpHTML === "disabled"
                                                ? "disabledSec"
                                                : watchSelect.bezelType ===
                                                  item["BezelType"]
                                                  ? "selected"
                                                  : ""
                                                } col-md-4`}
                                              onClick={() =>
                                                watchSelectHandler(
                                                  "bezelType",
                                                  item["BezelType"],
                                                  "dtBezelType"
                                                )
                                              }
                                              key={item["BezelType"]}
                                            >
                                              <div
                                                className={`img-section img-section-sp`}
                                              >
                                                <img
                                                  src={item.img}
                                                  alt="rolex"
                                                />
                                              </div>
                                              <div className={`watch-desc-sm`}>
                                                <h4>
                                                  {item["BezelType"] ===
                                                    "Beadset"
                                                    ? "Beadset Custom"
                                                    : item["BezelType"] ===
                                                      "Fluted"
                                                      ? "Fluted Original"
                                                      : item["BezelType"] ===
                                                        "Channel"
                                                        ? "Channel Custom"
                                                        : item["BezelType"] ===
                                                          "Pave"
                                                          ? "Pave Custom"
                                                          : item["BezelType"] ===
                                                            "Smooth"
                                                            ? "Smooth Original"
                                                            : ""}
                                                </h4>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="selection-title">
                                    <h5>Choose Bezel Metal</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtBezelMetal.map((item, index) => {
                                        if (
                                          Object.keys(item).length !== 0 &&
                                          Object.keys(item["Bezel Metal"])
                                            .length !== 0 &&
                                          Object.keys(item.img).length !== 0
                                        ) {
                                          return (
                                            <div
                                              className={`selection__box ${Object.keys(temp1).length !==
                                                0 &&
                                                bezelMetalDisable[
                                                item["Bezel Metal"]
                                                ] &&
                                                temp1["dtBezelMetal"][index]
                                                  .drpHTML === "disabled"
                                                ? "disabledSec"
                                                : watchSelect.bezelMetal ===
                                                  item["Bezel Metal"]
                                                  ? "selected"
                                                  : ""
                                                } col-md-3`}
                                              onClick={() =>
                                                watchSelectHandler(
                                                  "bezelMetal",
                                                  item["Bezel Metal"],
                                                  "dtBezelMetal"
                                                )
                                              }
                                            >
                                              <div
                                                className={`img-section img-section-sp`}
                                              >
                                                <img
                                                  src={item.img}
                                                  alt="rolex"
                                                />
                                              </div>
                                              <div className={`watch-desc-sm`}>
                                                <h4>
                                                  {item["Bezel Metal"] ===
                                                    "18KW"
                                                    ? "18K White"
                                                    : item["Bezel Metal"] ===
                                                      "18KY"
                                                      ? "18K Yellow"
                                                      : item["Bezel Metal"]}
                                                </h4>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-md-6">
                                  <div className="selection-title">
                                    <h5>Choose Bezel Gemstone Type</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtBezelGemstones.map(
                                        (item, index) => {
                                          if (
                                            Object.keys(item).length !== 0 &&
                                            Object.keys(item["BezelGemstones"])
                                              .length !== 0 &&
                                            Object.keys(item.img).length !== 0
                                          ) {
                                            return (
                                              <div
                                                className={`selection__box ${Object.keys(temp1).length !==
                                                  0 &&
                                                  bezelGemstonesDisable[
                                                  item["BezelGemstones"]
                                                  ] &&
                                                  temp1["dtBezelGemstones"][
                                                    index
                                                  ].drpHTML === "disabled"
                                                  ? "disabledSec"
                                                  : watchSelect.bezelGemstoneType ===
                                                    item["BezelGemstones"]
                                                    ? "selected"
                                                    : ""
                                                  } col-md-4`}
                                                onClick={() =>
                                                  watchSelectHandler(
                                                    "bezelGemstoneType",
                                                    item["BezelGemstones"],
                                                    "dtBezelGemstones"
                                                  )
                                                }
                                                key={item["BezelGemstones"]}
                                              >
                                                <div
                                                  className={`img-section img-section-sp`}
                                                >
                                                  <img
                                                    src={item.img}
                                                    alt="rolex"
                                                  />
                                                </div>
                                                <div
                                                  className={`watch-desc-sm`}
                                                >
                                                  <h4>
                                                    {item["BezelGemstones"] ===
                                                      "Diamond"
                                                      ? "Diamond Custom"
                                                      : item[
                                                        "BezelGemstones"
                                                      ] ===
                                                        "Diamond & Emerald"
                                                        ? "Diamond Emerald Custom"
                                                        : item[
                                                          "BezelGemstones"
                                                        ] === "Diamond & Ruby"
                                                          ? "Diamond Ruby Custom"
                                                          : item[
                                                            "BezelGemstones"
                                                          ] ===
                                                            "Diamond & Sapphire"
                                                            ? "Diamond Sapphire Custom"
                                                            : ""}
                                                  </h4>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-6">
                                  <div className="selection-title">
                                    <h5>Choose Bezel Gemstone Shape</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtBezelGemstoneShape.map(
                                        (item, index) => {
                                          if (
                                            Object.keys(item).length !== 0 &&
                                            Object.keys(
                                              item["Bezel Gemstone Shape"]
                                            ).length !== 0 &&
                                            Object.keys(item["img"]).length !==
                                            0
                                          ) {
                                            return (
                                              <div
                                                className={`selection__box ${Object.keys(temp1).length !==
                                                  0 &&
                                                  bezelGemstoneShapeDisable[
                                                  item["Bezel Gemstone Shape"]
                                                  ] &&
                                                  temp1["dtBezelGemstoneShape"][
                                                    index
                                                  ].drpHTML === "disabled"
                                                  ? "disabledSec"
                                                  : watchSelect.bezelGemstoneShape ===
                                                    item[
                                                    "Bezel Gemstone Shape"
                                                    ]
                                                    ? "selected"
                                                    : ""
                                                  } col-md-4`}
                                                onClick={() =>
                                                  watchSelectHandler(
                                                    "bezelGemstoneShape",
                                                    item[
                                                    "Bezel Gemstone Shape"
                                                    ],
                                                    "dtBezelGemstoneShape"
                                                  )
                                                }
                                                key={
                                                  item["Bezel Gemstone Shape"]
                                                }
                                              >
                                                <div
                                                  className={`img-section img-section-sp`}
                                                >
                                                  <img
                                                    src={item.img}
                                                    alt="rolex"
                                                  />
                                                </div>

                                                <div
                                                  className={`watch-desc-sm`}
                                                >
                                                  <h4>
                                                    {
                                                      item[
                                                      "Bezel Gemstone Shape"
                                                      ]
                                                    }
                                                  </h4>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-6">
                                  <div className="selection-title">
                                    <h5>Choose Bezel Diamond Quality</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtBezelDiamondQuality.map(
                                        (item, index) => {
                                          if (
                                            Object.keys(item).length !== 0 &&
                                            Object.keys(
                                              item["Bezel Diamond Quality"]
                                            ).length !== 0 &&
                                            Object.keys(item.img).length !== 0
                                          ) {
                                            return (
                                              <div
                                                className={`selection__box ${Object.keys(temp1).length !==
                                                  0 &&
                                                  bezelDiamondQualityDisable[
                                                  item[
                                                  "Bezel Diamond Quality"
                                                  ]
                                                  ] &&
                                                  temp1[
                                                    "dtBezelDiamondQuality"
                                                  ][index].drpHTML ===
                                                  "disabled"
                                                  ? "disabledSec"
                                                  : watchSelect.bezelDiamondQuality ===
                                                    item[
                                                    "Bezel Diamond Quality"
                                                    ]
                                                    ? "selected"
                                                    : ""
                                                  } col-md-4`}
                                                onClick={() =>
                                                  watchSelectHandler(
                                                    "bezelDiamondQuality",
                                                    item[
                                                    "Bezel Diamond Quality"
                                                    ],
                                                    "dtBezelDiamondQuality"
                                                  )
                                                }
                                                key={
                                                  item["Bezel Diamond Quality"]
                                                }
                                              >
                                                <div
                                                  className={`img-section img-section-sp`}
                                                >
                                                  <img
                                                    src={item.img}
                                                    alt="rolex"
                                                  />
                                                </div>

                                                <div
                                                  className={`watch-desc-sm`}
                                                >
                                                  <h4>
                                                    {
                                                      item[
                                                      "Bezel Diamond Quality"
                                                      ]
                                                    }
                                                  </h4>
                                                </div>
                                              </div>
                                            );
                                          }
                                        }
                                      )}
                                  </div>
                                </div>
                                {watchReqData &&
                                  watchReqData.dtWatch[0].CaratDropdownData !==
                                  "[]" && (
                                    <div className="col-md-6">
                                      <div className="selection-title">
                                        <h5>Choose Diamond Weight</h5>
                                      </div>
                                      <div className="row">
                                        <div className="col-md-6 input__block">
                                          <div className="selection-title">
                                            <h6>Total Carat Weight</h6>
                                          </div>
                                          <Select
                                            className="border__grey"
                                            optionFilterProp="children"
                                            value={watchSelect.carat}
                                            onChange={(e) =>
                                              watchDropSelectHandler(e, "carat")
                                            }
                                          >
                                            {watchReqData &&
                                              watchReqData.dtWatch.map(
                                                (item) => {
                                                  if (
                                                    item.CaratDropdownData &&
                                                    item.CaratDropdownData !==
                                                    "dtDiamondCarat" &&
                                                    Object.keys(
                                                      item.CaratDropdownData
                                                    ).length !== 0
                                                  ) {
                                                    let data = JSON.parse(
                                                      item.CaratDropdownData
                                                    );
                                                    let tempData;
                                                    tempData = data.map(
                                                      (res) => {
                                                        if (
                                                          res.Value !== "0" &&
                                                          res !== "disabled"
                                                        ) {
                                                          return (
                                                            <Option
                                                              value={res.Value}
                                                              key={res.Text}
                                                            >
                                                              {res.Text}
                                                            </Option>
                                                          );
                                                        }
                                                      }
                                                    );
                                                    return tempData;
                                                  }
                                                }
                                              )}
                                          </Select>
                                        </div>
                                        {showGemWt && (
                                          <div className="col-md-6 input__block">
                                            <div className="selection-title">
                                              <h6>Total Gem Weight</h6>
                                            </div>
                                            <Select
                                              className="border__grey"
                                              optionFilterProp="children"
                                              value="0.24"
                                              onChange=""
                                            >
                                              <Option value="0.24">0.24</Option>
                                            </Select>
                                          </div>
                                        )}
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                          <Accordion.Item eventKey="2">
                            <Accordion.Header>
                              <div className="accordian__headtext watchCustomizer-accordian-headtext">
                                <label>
                                  <span>STEP 3 | Dial Type</span>
                                  {/* <span>{dialTypeSpecification()}</span> */}
                                  <span className="watchCustomizer-captilize-sp">
                                    {dialTypeSpecification()}
                                  </span>
                                </label>
                              </div>
                            </Accordion.Header>
                            <Accordion.Body>
                              <div className="row mt-4">
                                <div className="col-md-12">
                                  <div className="selection-title">
                                    <h5>Choose Dial Type</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtDialType.map((item, index) => {
                                        if (
                                          Object.keys(item).length !== 0 &&
                                          Object.keys(item.DialType).length !==
                                          0 &&
                                          Object.keys(item.img).length !== 0
                                        ) {
                                          return (
                                            <div
                                              className={`selection__box ${Object.keys(temp1).length !==
                                                0 &&
                                                dialTypeDisable[
                                                item["DialType"]
                                                ] &&
                                                temp1["dtDialType"][index]
                                                  .drpHTML === "disabled"
                                                ? "disabledSec"
                                                : watchSelect.dialType ===
                                                  item.DialType
                                                  ? "selected"
                                                  : ""
                                                } col-md-6`}
                                              onClick={() =>
                                                watchSelectHandler(
                                                  "dialType",
                                                  item.DialType,
                                                  "dtDialType"
                                                )
                                              }
                                              key={item.DialType}
                                            >
                                              <div
                                                className={`img-section img-section-sp`}
                                              >
                                                <img
                                                  src={item.img}
                                                  alt="rolex"
                                                />
                                              </div>

                                              <div className={`watch-desc-sm`}>
                                                <h4>
                                                  {item.DialType ===
                                                    "Original DateJust 6917"
                                                    ? "DateJust"
                                                    : item.DialType ===
                                                      "Day-Date"
                                                      ? "Day-Date"
                                                      : ""}
                                                </h4>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>

                              <div className="row mt-4">
                                <div className="col-md-12">
                                  <div className="selection-title">
                                    <h5>Marker Type</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtMarkerType.map((item, index) => {
                                        if (
                                          Object.keys(item).length !== 0 &&
                                          Object.keys(item["Marker Type"])
                                            .length !== 0 &&
                                          Object.keys(item.img).length !== 0
                                        ) {
                                          return (
                                            <div
                                              className={`selection__box ${Object.keys(temp1).length !==
                                                0 &&
                                                markerTypeDisable[
                                                item["Marker Type"]
                                                ] &&
                                                temp1["dtMarkerType"][index]
                                                  .drpHTML === "disabled"
                                                ? "disabledSec"
                                                : watchSelect.markerType ===
                                                  item["Marker Type"]
                                                  ? "selected"
                                                  : ""
                                                } col-md-2`}
                                              onClick={() =>
                                                watchSelectHandler(
                                                  "markerType",
                                                  item["Marker Type"],
                                                  "dtMarkerType"
                                                )
                                              }
                                              key={item["Marker Type"]}
                                            >
                                              <div
                                                className={`img-section img-section-sp`}
                                              >
                                                <img
                                                  src={item.img}
                                                  alt="rolex"
                                                />
                                              </div>

                                              <div className={`watch-desc-sm`}>
                                                <h4 className="wrap-text">
                                                  {item["Marker Type"] ===
                                                    "Stick"
                                                    ? "Original Stick"
                                                    : item["Marker Type"] ===
                                                      "Roman Numeral"
                                                      ? "Original Roman Numeral"
                                                      : item["Marker Type"] ===
                                                        "Diamond"
                                                        ? "Custom Diamond"
                                                        : item["Marker Type"] ===
                                                          "Custom MOP Dial"
                                                          ? "Custom MOP Diamond"
                                                          : item["Marker Type"] ===
                                                            "Ruby"
                                                            ? "Custom MOP Ruby"
                                                            : item["Marker Type"] ===
                                                              "Emerald"
                                                              ? "Custom MOP Emerald"
                                                              : item["Marker Type"] ===
                                                                "Sapphire"
                                                                ? "Custom MOP Sapphire"
                                                                : item["Marker Type"] ===
                                                                  "Plain"
                                                                  ? "Custom MOP Plain"
                                                                  : item["Marker Type"]}
                                                </h4>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                              <div className="row mt-4">
                                <div className="col-md-12">
                                  <div className="selection-title">
                                    <h5>Custom Dial Colors</h5>
                                  </div>
                                  <div className="selection__block row m-0">
                                    {temp1 &&
                                      Object.keys(temp1).length !== 0 &&
                                      temp1.dtDialColor.map((item, index) => {
                                        if (
                                          Object.keys(item).length !== 0 &&
                                          Object.keys(item.DialColor).length !==
                                          0 &&
                                          Object.keys(item.img).length !== 0
                                        ) {
                                          return (
                                            <div
                                              className={`selection__box ${Object.keys(temp1).length !==
                                                0 &&
                                                dialColorDisable[
                                                item["DialColor"]
                                                ] &&
                                                temp1["dtDialColor"][index]
                                                  .drpHTML === "disabled"
                                                ? "disabledSec"
                                                : watchSelect.dialColor ===
                                                  item.DialColor
                                                  ? "selected"
                                                  : ""
                                                } col-md-2`}
                                              onClick={() =>
                                                watchSelectHandler(
                                                  "dialColor",
                                                  item.DialColor,
                                                  "dtDialColor"
                                                )
                                              }
                                              key={item.DialColor}
                                            >
                                              <div
                                                className={`img-section img-section-sp`}
                                              >
                                                <img
                                                  src={item.img}
                                                  alt="rolex"
                                                />
                                              </div>

                                              <div className={`watch-desc-sm`}>
                                                <h4>{item.DialColor}</h4>
                                              </div>
                                            </div>
                                          );
                                        }
                                      })}
                                  </div>
                                </div>
                              </div>
                            </Accordion.Body>
                          </Accordion.Item>
                        </Accordion>
                      </div>
                    </Tab>
                    <Tab eventKey="specifications" title="Specifications">
                      <div className="header-text">
                        <h3 className="subheading">
                          Configure & Customize Your Pre-Owned Rolex Watch
                        </h3>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="tab__contentdiv border__block p-0">
                            <div className="head__div">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4>Size & Bracelet Type (STEP 1) </h4>
                                </div>
                                <div className="col-md-6 text-right text-left-mobile">
                                  <span>{watchSizeSpecification()}</span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="vert-table">
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>SKU Number</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <input
                                          type="text"
                                          edit="false"
                                          value={
                                            watchReqData &&
                                            watchReqData.dtWatch &&
                                            watchReqData.dtWatch[0].StyleNumber
                                          }
                                        />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Watch Type</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.watchSize}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "watchSize"
                                            )
                                          }
                                        >
                                          <Option value="">-- Select --</Option>
                                          <Option value="26mm">Ladies</Option>
                                          <Option value="36mm">Men</Option>
                                          <Option value="31mm">MidSize</Option>
                                        </Select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Bracelet Style</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.braceletStyle}
                                          placeholder="Select Start Time"
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "braceletStyle",
                                              "dtBraceletType"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select Bracelet Style --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtBraceletType.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(temp1).length !==
                                                  0 &&
                                                  (!braceletStyleDisable[
                                                    item["Bracelet Style"]
                                                  ] ||
                                                    temp1["dtBraceletType"][
                                                      index
                                                    ].drpHTML !== "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`${index} ${item["Bracelet Style"]}`}
                                                      value={
                                                        item["Bracelet Style"]
                                                      }
                                                    >
                                                      {item["Bracelet Style"]}
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Bracelet Metal</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.braceletMetal}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "braceletMetal",
                                              "dtBraceletMetal"
                                            )
                                          }
                                          placeholder="Select Start Time"
                                        >
                                          <Option value="">
                                            -- Select Bracelet Metal --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtBraceletMetal.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(temp1).length !==
                                                  0 &&
                                                  (!braceletMetalDisable[
                                                    item["Bracelet Metal"]
                                                  ] ||
                                                    temp1["dtBraceletMetal"][
                                                      index
                                                    ].drpHTML !== "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`${index} ${item["Bracelet Metal"]}`}
                                                      value={
                                                        item["Bracelet Metal"]
                                                      }
                                                    >
                                                      {item["Bracelet Metal"]}
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="tab__contentdiv border__block p-0">
                            <div className="head__div">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4>Bezel Type (STEP 2) </h4>
                                </div>
                                <div className="col-md-6 text-right text-left-mobile">
                                  <span>{bezelTypeSpecification()}</span>
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="vert-table">
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Custom Bezel Type</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.bezelType}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "bezelType",
                                              "dtBezelType"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select BezelType --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtBezelType.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(temp1).length !==
                                                  0 &&
                                                  (!bezelTypeDisable[
                                                    item["BezelType"]
                                                  ] ||
                                                    temp1["dtBezelType"][index]
                                                      .drpHTML !== "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`${index} ${item["BezelType"]}`}
                                                      value={item["BezelType"]}
                                                    >
                                                      {item["BezelType"]}
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Custom Bezel Metal</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.bezelMetal}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "bezelMetal",
                                              "dtBezelMetal"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select Bezel Metal --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtBezelMetal.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(temp1).length !==
                                                  0 &&
                                                  (!bezelMetalDisable[
                                                    item["Bezel Metal"]
                                                  ] ||
                                                    temp1["dtBezelMetal"][index]
                                                      .drpHTML !== "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`${index} ${item["Bezel Metal"]}`}
                                                      value={
                                                        item["Bezel Metal"]
                                                      }
                                                    >
                                                      {item["Bezel Metal"]}
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Custom Bezel Gemstones</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.bezelGemstoneType}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "bezelGemstoneType",
                                              "dtBezelGemstones"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select Bezel Gemstones --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtBezelGemstones.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(
                                                    item["BezelGemstones"]
                                                  ).length !== 0 &&
                                                  (!bezelGemstonesDisable[
                                                    item["BezelGemstones"]
                                                  ] ||
                                                    temp1["dtBezelGemstones"][
                                                      index
                                                    ].drpHTML !== "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`${index} ${item["BezelGemstones"]}`}
                                                      value={
                                                        item["BezelGemstones"]
                                                      }
                                                    >
                                                      {item["BezelGemstones"]}
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Custom Bezel Gemstone Shape</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.bezelGemstoneShape}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "bezelGemstoneShape",
                                              "dtBezelGemstoneShape"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select Bezel Gemstone Shape --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtBezelGemstoneShape.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(
                                                    item["Bezel Gemstone Shape"]
                                                  ).length !== 0 &&
                                                  (!bezelGemstoneShapeDisable[
                                                    item["Bezel Gemstone Shape"]
                                                  ] ||
                                                    temp1[
                                                      "dtBezelGemstoneShape"
                                                    ][index].drpHTML !==
                                                    "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`index ${item["Bezel Gemstone Shape"]}`}
                                                      value={
                                                        item[
                                                        "Bezel Gemstone Shape"
                                                        ]
                                                      }
                                                    >
                                                      {
                                                        item[
                                                        "Bezel Gemstone Shape"
                                                        ]
                                                      }
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>
                                        Custom Bezel Diamond Quality
                                      </label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={
                                            watchSelect.bezelDiamondQuality
                                          }
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "bezelDiamondQuality",
                                              "dtBezelDiamondQuality"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select Bezel Diamond Quality --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtBezelDiamondQuality.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(
                                                    item[
                                                    "Bezel Diamond Quality"
                                                    ]
                                                  ).length !== 0 &&
                                                  (!bezelDiamondQualityDisable[
                                                    item[
                                                    "Bezel Diamond Quality"
                                                    ]
                                                  ] ||
                                                    temp1[
                                                      "dtBezelDiamondQuality"
                                                    ][index].drpHTML !==
                                                    "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`index ${item["Bezel Diamond Quality"]}`}
                                                      value={
                                                        item[
                                                        "Bezel Diamond Quality"
                                                        ]
                                                      }
                                                    >
                                                      {
                                                        item[
                                                        "Bezel Diamond Quality"
                                                        ]
                                                      }
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Total Carat Weight</label>
                                    </div>
                                    <div className="col-md-5">
                                      {watchReqData &&
                                        watchReqData.dtWatch[0]
                                          .CaratDropdownData !== "[]" && (
                                          <div className="input__block mb-0">
                                            <Select
                                              className="border__grey"
                                              optionFilterProp="children"
                                              value={watchSelect.carat}
                                              onChange={(e) =>
                                                watchDropSelectHandler(
                                                  e,
                                                  "carat"
                                                )
                                              }
                                            >
                                              {watchReqData &&
                                                watchReqData.dtWatch.map(
                                                  (item) => {
                                                    if (
                                                      item.CaratDropdownData &&
                                                      item.CaratDropdownData !==
                                                      "dtDiamondCarat" &&
                                                      Object.keys(
                                                        item.CaratDropdownData
                                                      ).length !== 0
                                                    ) {
                                                      let data = JSON.parse(
                                                        item.CaratDropdownData
                                                      );
                                                      let tempData;
                                                      tempData = data.map(
                                                        (res) => {
                                                          if (
                                                            res.Value !== "0" &&
                                                            res !== "disabled"
                                                          ) {
                                                            return (
                                                              <Option
                                                                value={
                                                                  res.Value
                                                                }
                                                                key={res.Text}
                                                              >
                                                                {res.Text}
                                                              </Option>
                                                            );
                                                          }
                                                        }
                                                      );
                                                      return tempData;
                                                    }
                                                  }
                                                )}
                                            </Select>
                                          </div>
                                        )}
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row mt-4">
                        <div className="col-md-12">
                          <div className="tab__contentdiv border__block p-0">
                            <div className="head__div">
                              <div className="row">
                                <div className="col-md-6">
                                  <h4>Dial Type (STEP 3) </h4>
                                </div>
                                <div className="col-md-6 text-right text-left-mobile">
                                  <span>{dialTypeSpecification()}</span>{" "}
                                </div>
                              </div>
                            </div>
                            <div className="row">
                              <div className="col-md-12">
                                <div className="vert-table">
                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Dial Type</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.dialType}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "dialType"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select DialType --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtDialType.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(temp1).length !==
                                                  0 &&
                                                  (!dialTypeDisable[
                                                    item["DialType"]
                                                  ] ||
                                                    temp1["dtDialType"][index]
                                                      .drpHTML !== "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`index ${item["DialType"]}`}
                                                      value={item["DialType"]}
                                                    >
                                                      {item["DialType"]}
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Marker Type</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.markerType}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "markerType",
                                              "dtMarkerType"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select Marker Type --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtMarkerType.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(temp1).length !==
                                                  0 &&
                                                  (!markerTypeDisable[
                                                    item["Marker Type"]
                                                  ] ||
                                                    temp1["dtMarkerType"][index]
                                                      .drpHTML !== "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`index ${item["Marker Type"]}`}
                                                      value={
                                                        item["Marker Type"]
                                                      }
                                                    >
                                                      {item["Marker Type"]}
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="row compare-row-sp">
                                    <div className="col-md-5">
                                      <label>Custom Dial Color</label>
                                    </div>
                                    <div className="col-md-5">
                                      <div className="input__block mb-0">
                                        <Select
                                          className="border__grey"
                                          optionFilterProp="children"
                                          value={watchSelect.dialColor}
                                          onChange={(e) =>
                                            watchDropSelectHandler(
                                              e,
                                              "dialColor",
                                              "dtDialColor"
                                            )
                                          }
                                        >
                                          <Option value="">
                                            -- Select Custom Dial Color --
                                          </Option>
                                          {temp1 &&
                                            Object.keys(temp1).length !== 0 &&
                                            temp1.dtDialColor.map(
                                              (item, index) => {
                                                if (
                                                  Object.keys(temp1).length !==
                                                  0 &&
                                                  (!dialColorDisable[
                                                    item["DialColor"]
                                                  ] ||
                                                    temp1["dtDialColor"][index]
                                                      .drpHTML !== "disabled")
                                                ) {
                                                  return (
                                                    <Option
                                                      key={`index ${item["DialColor"]}`}
                                                      value={item["DialColor"]}
                                                    >
                                                      {item["DialColor"]}
                                                    </Option>
                                                  );
                                                }
                                              }
                                            )}
                                        </Select>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab>
                  </Tabs>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* Modal Section Starts */}

        {/* Request More Info Modal Starts */}
        <Modal
          title="Request More Information"
          width={700}
          style={{ top: 20 }}
          visible={isModalVisible1}
          className="request_info"
          onCancel={handleCancel1}
          footer={[
            <Button key="back" onClick={handleCancel1}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={requestSubmitHandler}>
              Add
            </Button>,
          ]}
        >
          <div className="row">
            <div className="col-md-12">
              <p>Our specialist will contact you.</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Name</label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={requestInfo.yourName}
                        onChange={(e) => requestInfoHandler(e)}
                        name="yourName" //IMPORTANT
                        className={reqValidations.yourName && "border__red"}
                      />
                      <div>
                        {reqValidations.yourName && (
                          <p className="error-color-red">
                            {reqValidations.yourName}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your E-Mail Address</label>
                      <input
                        type="text"
                        placeholder="Enter Your E-Mail Address"
                        value={requestInfo.yourEmail}
                        onChange={(e) => requestInfoHandler(e)}
                        name="yourEmail" //IMPORTANT
                        className={reqValidations.yourEmail && "border__red"}
                      />
                      <div>
                        {reqValidations.yourEmail && (
                          <p className="error-color-red">
                            {reqValidations.yourEmail}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Company Name</label>
                      <input
                        type="text"
                        placeholder="Enter Your Company Name"
                        value={requestInfo.yourCompName}
                        onChange={(e) => requestInfoHandler(e)}
                        name="yourCompName"
                        className={reqValidations.yourCompName && "border__red"}
                      />
                      <div>
                        {reqValidations.yourCompName && (
                          <p className="error-color-red">
                            {reqValidations.yourCompName}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Phone Number</label>
                      <input
                        type="number"
                        placeholder="Enter Your Phone Number"
                        value={requestInfo.yourPhone}
                        onChange={(e) => requestInfoHandler(e)}
                        name="yourPhone"
                        className={reqValidations.yourPhone && "border__red"}
                      />
                      <div>
                        {reqValidations.yourPhone && (
                          <p className="error-color-red">
                            {reqValidations.yourPhone}
                          </p>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Store Phone Number</label>
                      <input
                        type="number"
                        placeholder="Your Store Phone Number"
                        value={requestInfo.yourStorePhone}
                        onChange={(e) => requestInfoHandler(e)}
                        name="yourStorePhone"
                        className={
                          reqValidations.yourStorePhone && "border__red"
                        }
                      />
                      {reqValidations.yourStorePhone && (
                        <p className="error-color-red">
                          {reqValidations.yourStorePhone}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Write A Personal Message ...</label>
                      <textarea
                        value={requestInfo.message}
                        placeholder="Write A Personal Message ..."
                        onChange={(e) => requestInfoHandler(e)}
                        name="message"
                        className={reqValidations.message && "border__red"}
                      ></textarea>
                      {reqValidations.message && (
                        <p className="error-color-red">
                          {reqValidations.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Request More Info Modal Ends */}

        {/* Drop A Hint Modal Starts */}
        <Modal
          title="Drop A Hint"
          width={700}
          style={{ top: 20 }}
          visible={isModalVisible2}
          className="drop_a_hint"
          onCancel={handleCancel2}
          footer={[
            <Button key="back" onClick={handleCancel2}>
              Cancel
            </Button>,
            <Button key="submit" type="primary" onClick={dropHintSubmitHandler}>
              Send
            </Button>,
          ]}
        >
          <div className="row">
            <div className="col-md-12">
              <p>Because you deserve this.</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Hint Recipient's Name</label>
                      <input
                        type="text"
                        placeholder="Enter Hint Recipient's Name"
                        value={dropHint.recptName}
                        onChange={(e) => dropHintChangeHandler(e)}
                        name="recptName" //IMPORTANT
                        className={hintValidations.recptName && "border__red"}
                      />
                      {hintValidations.recptName && (
                        <p className="error-color-red">
                          {hintValidations.recptName}
                        </p>
                      )}
                      <div></div>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Hint Recipient's E-Mail</label>
                      <input
                        type="text"
                        placeholder="Enter Hint Recipient's E-Mail"
                        value={dropHint.recptEmail}
                        onChange={(e) => dropHintChangeHandler(e)}
                        name="recptEmail" //IMPORTANT
                        className={hintValidations.recptEmail && "border__red"}
                      />
                      {hintValidations.recptEmail && (
                        <p className="error-color-red">
                          {hintValidations.recptEmail}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Reason For This Gift</label>
                      <input
                        type="text"
                        placeholder="Enter Reason For This Gift"
                        value={dropHint.recptReason}
                        onChange={(e) => dropHintChangeHandler(e)}
                        name="recptReason" //IMPORTANT
                        className={hintValidations.recptReason && "border__red"}
                      />
                      {hintValidations.recptReason && (
                        <p className="error-color-red">
                          {hintValidations.recptReason}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block datepicker__block">
                      <label>Gift Deadline</label>
                      {/* <DatePicker
                        className={`date-range-input ${hintValidations.hintRecptGiftDeadline &&
                          "border__red"
                          }`}
                        format="MM/DD/YYYY"
                        onChange={onDateChange}
                      /> */}
                      <input
                        name="hintRecptGiftDeadline"
                        min={disablePastDate()}
                        // value={dropHint.hintRecptGiftDeadline ? moment(dropHint.hintRecptGiftDeadline).format("MM-DD-YYYY") : ""}
                        value={dropHint.hintRecptGiftDeadline}
                        onChange={(e) => {
                          onDateChange(e, "hintRecptGiftDeadline");
                        }}
                        type="date"
                        placeholder="Enter Date"
                        className={`date-range-input ${hintValidations.hintRecptGiftDeadline && "border__red"
                          }`}
                      />
                      {hintValidations.hintRecptGiftDeadline && (
                        <p className="error-color-red">
                          {hintValidations.hintRecptGiftDeadline}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Add A Personal Message Here ...</label>
                      <textarea
                        value={dropHint.recpMessage}
                        placeholder="Add A Personal Message Here ..."
                        onChange={(e) => dropHintChangeHandler(e)}
                        name="recpMessage" //IMPORTANT
                        className={hintValidations.recpMessage && "border__red"}
                      ></textarea>
                      {hintValidations.recpMessage && (
                        <p className="error-color-red">
                          {hintValidations.recpMessage}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Drop A Hint Modal Ends */}

        {/* E-Mail A Friend Modal Starts */}
        <Modal
          title="E-Mail A Friend"
          width={700}
          style={{ top: 20 }}
          visible={isModalVisible3}
          className="email_a_friend"
          onCancel={handleCancel3}
          footer={[
            <Button key="back" onClick={handleCancel3}>
              Cancel
            </Button>,
            <Button
              key="submit"
              type="primary"
              onClick={emailFriendSubmitHandler}
            >
              Add
            </Button>,
          ]}
        >
          <div className="row">
            <div className="col-md-12">
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Name</label>
                      <input
                        type="text"
                        placeholder="Enter Your Name"
                        value={emailFriend.yourName}
                        onChange={(e) => emailFriendHandler(e)}
                        name="yourName"
                        className={
                          emailFrenValidations.yourName && "border__red"
                        }
                      />
                      {emailFrenValidations.yourName && (
                        <p className="error-color-red">
                          {emailFrenValidations.yourName}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your E-Mail Address</label>
                      <input
                        type="text"
                        placeholder="Enter Your E-Mail Address"
                        value={emailFriend.yourMail}
                        onChange={(e) => emailFriendHandler(e)}
                        name="yourMail"
                        className={
                          emailFrenValidations.yourMail && "border__red"
                        }
                      />
                      {emailFrenValidations.yourMail && (
                        <p className="error-color-red">
                          {emailFrenValidations.yourMail}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Friend E-Mail Address</label>
                      <input
                        type="text"
                        placeholder="Enter Friend E-Mail Address"
                        value={emailFriend.friendMail}
                        onChange={(e) => emailFriendHandler(e)}
                        name="friendMail"
                        className={
                          emailFrenValidations.friendMail && "border__red"
                        }
                      />
                      {emailFrenValidations.friendMail && (
                        <p className="error-color-red">
                          {emailFrenValidations.friendMail}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Subject</label>
                      <input
                        type="text"
                        placeholder="Enter Subject"
                        value={emailFriend.subject}
                        onChange={(e) => emailFriendHandler(e)}
                        name="subject"
                        className={
                          emailFrenValidations.subject && "border__red"
                        }
                      />
                      {emailFrenValidations.subject && (
                        <p className="error-color-red">
                          {emailFrenValidations.subject}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Write A Personal Message ...</label>
                      <textarea
                        value={emailFriend.message}
                        placeholder="Write A Personal Message ..."
                        onChange={(e) => emailFriendHandler(e)}
                        name="message"
                        className={
                          emailFrenValidations.message && "border__red"
                        }
                      ></textarea>
                      {emailFrenValidations.message && (
                        <p className="error-color-red">
                          {emailFrenValidations.message}
                        </p>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* E-Mail A Friend Modal Ends */}

        {/* Print Details Modal Starts */}
        <Modal
          title="Print Details"
          //visible={isModalVisible4}
          className="print_details"
          style={{ top: 20 }}
          onCancel={handleCancel4}
        >
          <div className="row mt-4">
            <div className="col-md-12">
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Name</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your E-Mail Address</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Company Name</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Phone Number</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Store Phone Number</label>
                      <input type="text" />
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Write A Personal Message</label>
                      <textarea></textarea>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Print Details Modal Ends */}

        {/* Schedule Viewing Modal Starts */}
        <Modal
          title="Schedule A Viewing"
          width={700}
          style={{ top: 20 }}
          visible={isModalVisible5}
          className="schedule_viewing"
          onCancel={handleCancel5}
          footer={[
            <Button key="back" onClick={handleCancel5}>
              Cancel
            </Button>,
            <Button
              className="hello"
              key="submit"
              type="primary"
              onClick={scheduleSubmitHandler}
            >
              Schedule
            </Button>,
          ]}
        >
          <div className="row">
            <div className="col-md-12">
              <p>See this item and more in our store</p>
              <div className="table__block">
                <div className="row p-0 border-0 form__fields mh-auto">
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Your Email</label>
                      <input
                        type="text"
                        placeholder="Enter Your Email"
                        value={schedule.email}
                        onChange={(e) => scheduleViewHandler(e, "email")}
                        name="email"
                        className={scheduleValidations.email && "border__red"}
                      />
                      {scheduleValidations.email && (
                        <p className="error-color-red">
                          {scheduleValidations.email}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Visiting For</label>
                      <Select
                        className={`border__grey ${scheduleValidations.appType && "border__red"
                          }`}
                        optionFilterProp="children"
                        value={schedule.appType}
                        onChange={(e) => scheduleViewHandlerDD(e, "appType")}
                        name="appType"
                      >
                        {appntmntDD &&
                          appntmntDD.HTMLData.data.map((item, index) => {
                            if (
                              Object.keys(item).length !== 0 &&
                              item.value !== "0"
                            ) {
                              return (
                                <Option value={item.value} key={index}>
                                  {item.text}
                                </Option>
                              );
                            }
                          })}
                      </Select>
                      {scheduleValidations.appType && (
                        <p className="error-color-red">
                          {scheduleValidations.appType}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block datepicker__block">
                      <label>When are you available?</label>
                      {/* <input type="date"
                        className={`date-range-input ${scheduleValidations.appDate && "border__red"}`}
                        format="MM/DD/YYYY"
                        onChange={onScheduleDateChange}

                      /> */}
                      {/* <DatePicker
                        className={`date-range-input ${scheduleValidations.appDate && "border__red"
                          }`}
                        format="MM/DD/YYYY"
                        onChange={onScheduleDateChange}
                      /> */}
                      <input
                        name="appDate"
                        // value={dropHint.hintRecptGiftDeadline ? moment(dropHint.hintRecptGiftDeadline).format("MM-DD-YYYY") : ""}
                        value={schedule.appDate}
                        min={disablePastDate()}
                        onChange={(e) => {
                          onScheduleDateChange(e);
                        }}
                        type="date"
                        placeholder="Enter Date"
                        className={`date-range-input ${scheduleValidations.appDate && "border__red"
                          }`}
                      />
                      {scheduleValidations.appDate && (
                        <p className="error-color-red">
                          {scheduleValidations.appDate}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="input__block">
                      <label>Appointment Time</label>
                      <Select
                        className={`border__grey ${scheduleValidations.appTime && "border__red"
                          }`}
                        optionFilterProp="children"
                        value={schedule.appTime}
                        onChange={(e) => scheduleViewHandlerDD(e, "appTime")}
                        name="appTime"
                      >
                        {appntmntDD &&
                          appntmntDD.HTMLDataTime.data.map((item, index) => {
                            if (
                              Object.keys(item).length !== 0 &&
                              item.value !== "0"
                            ) {
                              return (
                                <Option value={item.value} key={index}>
                                  {item.text}
                                </Option>
                              );
                            }
                          })}
                      </Select>
                      {scheduleValidations.appTime && (
                        <p className="error-color-red">
                          {scheduleValidations.appTime}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="input__block">
                      <label>Add A Message Here ...</label>
                      <textarea
                        placeholder="Add A Message Here ..."
                        value={schedule.message}
                        onChange={(e) => scheduleViewHandler(e, "message")}
                        name="message"
                        className={scheduleValidations.message && "border__red"}
                      ></textarea>
                      {scheduleValidations.message && (
                        <p className="error-color-red">
                          {scheduleValidations.message}
                        </p>
                      )}
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="request-info-links">
                      <a href="#" onClick={openMapHandler}>
                        Get Directions
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </Modal>
        {/* Schedule Viewing Modal Ends */}
        <div style={{ display: "none" }}>
          <PrintOutComp ref={componentRef} data={watchSelect} />
        </div>

        {/* Modal Section Ends */}
      </div>
    </React.Fragment>
  );
};

export default WatchCustomizer;
