import React from "react";
import { NavLink } from "react-router-dom";
import SettingIcon from "../assets/images/icons/setting__icon.svg";
import DiamondIcon from "../assets/images/icons/diamond__icon.svg";
import RingIcon from "../assets/images/icons/ring__icon.svg";
import PendantSettingIcon from "../assets/images/icons/pendantchoose_setting.svg";
import PendantCompleteIcon from "../assets/images/icons/complete__pendant.svg";

import StudMountingIcon from "../assets/images/icons/stud_mounting.svg";
import StudCompleteIcon from "../assets/images/icons/complete__stud.svg";

export const Header = () => {
    return (
        // <div>
        //     <ul className="breadcrumb wizard">
        //         <li className="active">
        //             <NavLink to={"/"}>
        //                 <p className="mb-0"><span className="circle">1</span> Choose Your Setting</p>
        //                 <img src={SettingIcon} />
        //             </NavLink>
        //         </li>
        //         <li>
        //             <NavLink to={"/selectYourDiamond"}>
        //                 <p className="mb-0"><span className="circle">2</span>Select Your Diamond</p>
        //                 <img src={DiamondIcon} />
        //             </NavLink>
        //         </li>
        //         <li>
        //             <NavLink to={"/completeYourRing"}>
        //                 <p className="mb-0"><span className="circle">3</span> Complete Your Ring</p>
        //                 <img src={RingIcon} />
        //             </NavLink>
        //         </li>
        //     </ul>
        // </div>

        <div className="bcontainer d-none">
            <div className="breadcrumb">
                <NavLink to={"/"}>
                    <p className="mb-0"><span className="circle">1</span>Select Your Diamond</p>
                    <img src={DiamondIcon} />
                </NavLink>

                <NavLink to={"/chooseYourSetting"}>
                    <p className="mb-0"><span className="circle">2</span> Choose Your Mounting</p>
                    <img src={StudMountingIcon} />
                </NavLink>
                

                <NavLink to={"/completeYourRing"}>
                      <p className="mb-0"><span className="circle">3</span> Complete Your Earrings</p>
                    <img src={StudCompleteIcon} />
               </NavLink>
            </div>
        </div>
    )

};

export default Header;