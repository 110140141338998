import React from "react";
import  {BrowserRouter,Route,Routes,Navigate,Outlet} from "react-router-dom";
import WatchCustomizer from "./component/ApplicationPreview/WatchCustomizer";
import Layout from "./component/Layout";
import { NotificationContainer } from "react-notifications";


const PrivateRoute = () =>{
  return(
    <Layout>
    <Outlet />
 </Layout>
  )
 
}

 const App = () => {
  return (
    <React.Fragment>
      <BrowserRouter>
      <Routes>
      <Route exact path="/" element={<PrivateRoute/>}>
      <Route path="/" element={<WatchCustomizer/>} />
        {/* <Route path="/chooseYourSetting" element={<ChooseYourSetting/>} />
        <Route path="/completeYourRing" element={<CompleteYourRing/>} />
        <Route path="/chooseSettingItemDetails" element={<ChooseSettingItemDetails/>} /> */}
        </Route>
      </Routes>
      <NotificationContainer />
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
