// let config = {
//     AppsAPIURL: "https://gem-appsapi-demo.brainvire.net/api",
// }
let config = {
  AppsAPIURL: "https://jc2-apps-api.stage.jewelcloud.com/api",
};

// let config = {
//     AppsAPIURL: "https://jc2-apps-api.mgr.brainvire.net/api",
// }
export default config;
